import React from "react";
import { UseFormReturn } from "react-hook-form";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { BatchFormValues } from "@/types/batch";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import { Badge } from "@/components/ui/badge";

interface BatchFormFieldsProps {
  form: UseFormReturn<BatchFormValues>;
}

export function BatchFormFields({ form }: BatchFormFieldsProps) {
  const { toast } = useToast();
  const [productDetails, setProductDetails] = React.useState<{
    name: string;
    is_weight_based: boolean;
  } | null>(null);
  const [searchResults, setSearchResults] = React.useState<Array<{ sku: string; item_name: string }>>([]);
  const [searchTerm, setSearchTerm] = React.useState("");

  const lookupSku = async (sku: string) => {
    if (!sku) {
      setProductDetails(null);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('inventory')
        .select('item_name, is_weight_based')
        .eq('sku', sku)
        .maybeSingle();

      if (error) throw error;

      if (data) {
        setProductDetails({
          name: data.item_name,
          is_weight_based: data.is_weight_based
        });
        
        // Clear the other field based on type
        if (data.is_weight_based) {
          form.setValue('quantity', undefined);
        } else {
          form.setValue('weight_kilos', undefined);
        }

        toast({
          title: "SKU Found",
          description: `Found ${data.item_name} in inventory`
        });
      } else {
        setProductDetails(null);
        toast({
          variant: "destructive",
          title: "Error",
          description: "SKU not found in inventory"
        });
      }
    } catch (error: any) {
      setProductDetails(null);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to lookup SKU"
      });
    }
  };

  const searchItems = async (term: string) => {
    if (!term) {
      setSearchResults([]);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('inventory')
        .select('sku, item_name')
        .ilike('item_name', `%${term}%`)
        .limit(5);

      if (error) throw error;
      setSearchResults(data || []);
    } catch (error) {
      console.error('Error searching items:', error);
      setSearchResults([]);
    }
  };

  return (
    <>
      <div className="relative mb-6">
        <div className="relative">
          <Input
            placeholder="✨ Type to search items..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              searchItems(e.target.value);
            }}
            className="bg-amber-50 hover:bg-amber-100 focus:bg-amber-100 transition-colors duration-200 pl-8 border-amber-200 animate-pulse-gentle placeholder:text-amber-700"
          />
          <span className="absolute left-2 top-1/2 -translate-y-1/2">
            🔍
          </span>
        </div>
        {searchResults.length > 0 && searchTerm && (
          <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
            {searchResults.map((item) => (
              <div
                key={item.sku}
                className="px-4 py-2 hover:bg-amber-50 cursor-pointer flex justify-between"
                onClick={() => {
                  form.setValue('sku', item.sku);
                  lookupSku(item.sku);
                  setSearchTerm('');
                  setSearchResults([]);
                }}
              >
                <span>{item.item_name}</span>
                <span className="text-gray-500">{item.sku}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <FormField
        control={form.control}
        name="sku"
        render={({ field }) => (
          <FormItem>
            <FormLabel>SKU</FormLabel>
            <FormControl>
              <div className="space-y-1">
                <Input 
                  placeholder="Enter SKU" 
                  {...field} 
                  onBlur={(e) => {
                    field.onBlur();
                    lookupSku(e.target.value);
                  }}
                />
                {productDetails && (
                  <Badge variant="secondary" className="w-fit">
                    {productDetails.name}
                  </Badge>
                )}
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {productDetails && !productDetails.is_weight_based && (
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Quantity</FormLabel>
              <FormControl>
                <Input 
                  type="number" 
                  placeholder="Enter quantity"
                  autoFocus
                  {...field}
                  onChange={(e) => field.onChange(e.target.value === '' ? undefined : Number(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      {productDetails && productDetails.is_weight_based && (
        <FormField
          control={form.control}
          name="weight_kilos"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Weight (kg)</FormLabel>
              <FormControl>
                <Input 
                  type="number"
                  step="0.001"
                  placeholder="Enter weight in kilos"
                  autoFocus
                  {...field}
                  onChange={(e) => field.onChange(e.target.value === '' ? undefined : Number(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <FormField
        control={form.control}
        name="purchase_cost"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Purchase Cost (CHF)</FormLabel>
            <FormControl>
              <Input 
                type="number"
                step="0.01"
                placeholder="Enter purchase cost"
                {...field}
                onChange={(e) => field.onChange(e.target.value === '' ? undefined : Number(e.target.value))}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="warehouse"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Warehouse</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select warehouse" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value="(+3°C)">(+3°C)</SelectItem>
                <SelectItem value="(+25°C)">(+25°C)</SelectItem>
                <SelectItem value="(-25°C)">(-25°C)</SelectItem>
                <SelectItem value="Paradiso">Paradiso</SelectItem>
                <SelectItem value="Consignment">Consignment</SelectItem>
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="date_received"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Date Received</FormLabel>
              <FormControl>
                <Input type="date" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="expiry_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Expiry Date</FormLabel>
              <FormControl>
                <Input type="date" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </>
  );
}