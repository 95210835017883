import React from "react";
import { LogOut, User } from "lucide-react";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Navigation from "@/components/Navigation";

const Header = () => {
  const { user, signOut, role } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Get display name from Google user info
  const displayName = user?.name || user?.email;
  const roleDisplay = role ? ` (${role})` : '';

  return (
    <header className="h-16 bg-white shadow-sm">
      <div className="h-full px-6 flex items-center justify-between bg-gradient-to-r from-[#304661] to-[#304661]/90">
        <Navigation />
        
        <div className="flex items-center space-x-6">
          <div className="flex items-center bg-white/10 px-4 py-1.5 rounded-md">
            <User className="h-5 w-5 text-[#C6D165] mr-2" />
            <span className="text-sm text-white">
              {displayName}{roleDisplay}
            </span>
          </div>
          <button
            onClick={handleSignOut}
            className="flex items-center text-sm text-[#C6D165] hover:text-[#99AE4C] transition-colors duration-200"
          >
            <LogOut className="h-5 w-5 mr-1" />
            Sign Out
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;