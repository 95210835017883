import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { formSchema, VATRate } from "@/types/inventory";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useAuth } from '@/contexts/AuthContext';

interface AddProductDialogProps {
  onProductAdded: () => void;
}

interface BatchInfo {
  batch_number: string;
  quantity: number;
  available_quantity: number;
  date_received: string;
}

export function AddProductDialog({ onProductAdded }: AddProductDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [recentBatches, setRecentBatches] = useState<BatchInfo[]>([]);
  const { canEdit } = useAuth();

  if (!canEdit) return null;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sku: "",
      item_name: "",
      description: "",
      vat_rate: 8.1 as VATRate,
      is_weight_based: false
    },
  });

  const fetchBatchInfo = async (sku: string) => {
    if (!sku) return;

    // Get total quantity from all batches
    const { data: batches, error: batchError } = await supabase
      .from('batches')
      .select('*')
      .eq('sku', sku)
      .order('date_received', { ascending: false });

    if (!batchError && batches) {
      const total = batches.reduce((sum, batch) => sum + (batch.available_quantity || 0), 0);
      setCurrentQuantity(total);
      setRecentBatches(batches.slice(0, 5)); // Show only 5 most recent batches
    }
  };

  const lookupSku = async (sku: string) => {
    if (!sku) return;

    const { data, error } = await supabase
      .from('inventory')
      .select('*')
      .eq('sku', sku)
      .single();

    if (error) {
      console.error('Error looking up SKU:', error);
      return;
    }

    if (data) {
      toast({
        title: "SKU Found",
        description: "This SKU already exists in the inventory.",
      });
      
      // Populate form with existing data
      form.setValue('item_name', data.item_name);
      form.setValue('description', data.description || '');
      form.setValue('vat_rate', data.vat_rate);

      // Fetch batch information
      await fetchBatchInfo(sku);
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { error } = await supabase
        .from('inventory')
        .upsert([values], { 
          onConflict: 'sku',
          ignoreDuplicates: false 
        });

      if (error) throw error;

      toast({
        title: "Product updated successfully",
        description: `${values.item_name} has been ${values.sku ? 'updated' : 'added'} to inventory.`
      });

      form.reset();
      setOpen(false);
      onProductAdded();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error saving product",
        description: error.message
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          Add Product
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Add New Product</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="sku"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>SKU</FormLabel>
                      <FormControl>
                        <Input 
                          placeholder="Enter SKU" 
                          {...field} 
                          onBlur={(e) => {
                            field.onBlur();
                            lookupSku(e.target.value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="item_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter product name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter description" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="vat_rate"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>VAT Rate</FormLabel>
                      <Select
                        value={field.value.toString()}
                        onValueChange={(value) => {
                          const rate = parseFloat(value) as VATRate;
                          if (rate === 8.1 || rate === 2.6) {
                            field.onChange(rate);
                          }
                        }}
                      >
                        <SelectTrigger className="bg-white text-gray-900 border-gray-200">
                          <SelectValue placeholder="Select VAT rate" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="8.1">8.1%</SelectItem>
                          <SelectItem value="2.6">2.6%</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_weight_based"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tracking Type</FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-2">
                          <span className={`text-sm ${!field.value ? 'font-bold text-blue-600' : ''}`}>QTY</span>
                          <button
                            type="button"
                            role="switch"
                            aria-checked={field.value}
                            className={`
                              relative inline-flex h-6 w-11 items-center rounded-full
                              ${field.value ? 'bg-red-600' : 'bg-blue-600'}
                              transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                            `}
                            onClick={() => field.onChange(!field.value)}
                          >
                            <span
                              className={`
                                ${field.value ? 'translate-x-6' : 'translate-x-1'}
                                inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                              `}
                            />
                          </button>
                          <span className={`text-sm ${field.value ? 'font-bold text-red-600' : ''}`}>Weight</span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Button type="submit" className="w-full">
                  {form.getValues('sku') ? 'Update' : 'Add'} Product
                </Button>
              </form>
            </Form>
          </div>
          
          <div className="space-y-4">
            <div className="p-4 bg-gray-50 rounded-lg">
              <h3 className="font-semibold mb-2">Current Stock</h3>
              <p className="text-2xl font-bold">{currentQuantity}</p>
            </div>

            {recentBatches.length > 0 && (
              <div>
                <h3 className="font-semibold mb-2">Recent Batches</h3>
                <div className="space-y-2">
                  {recentBatches.map((batch) => (
                    <div 
                      key={batch.batch_number}
                      className="p-3 bg-gray-50 rounded-lg"
                    >
                      <div className="flex justify-between items-center">
                        <span className="font-medium">{batch.batch_number}</span>
                        <span className="text-sm text-gray-500">
                          {new Date(batch.date_received).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="text-sm text-gray-600 mt-1">
                        Available: {batch.available_quantity} / {batch.quantity}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}