import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";

interface AuditResultsDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    results: Array<{
        batchNumber: string;
        itemName: string;
        sku: string;
        storedQuantity: number;
        calculatedQuantity: number;
        difference: number;
        isWeightBased: boolean;
    }>;
    onReconcile: (result: any) => void;
}

export function AuditResultsDialog({
    open,
    onOpenChange,
    results,
    onReconcile
}: AuditResultsDialogProps) {
    const formatQuantity = (quantity: number, isWeightBased: boolean) => {
        return isWeightBased ? `${quantity.toFixed(2)} kg` : `${Math.round(quantity)} units`;
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-4xl">
                <DialogHeader>
                    <DialogTitle>Audit Results</DialogTitle>
                </DialogHeader>
                <div className="mt-4">
                    <p className="text-sm text-gray-500 mb-4">
                        Found {results.length} discrepancies that need attention.
                    </p>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Batch #</TableHead>
                                <TableHead>Item</TableHead>
                                <TableHead>SKU</TableHead>
                                <TableHead>System Value</TableHead>
                                <TableHead>Expected Value</TableHead>
                                <TableHead>Difference</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {results.map((result) => (
                                <TableRow key={result.batchNumber}>
                                    <TableCell>{result.batchNumber}</TableCell>
                                    <TableCell>{result.itemName}</TableCell>
                                    <TableCell>{result.sku}</TableCell>
                                    <TableCell>{formatQuantity(result.storedQuantity, result.isWeightBased)}</TableCell>
                                    <TableCell>{formatQuantity(result.calculatedQuantity, result.isWeightBased)}</TableCell>
                                    <TableCell className="text-red-500">
                                        {formatQuantity(result.difference, result.isWeightBased)}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={() => onReconcile(result)}
                                        >
                                            Mark Reconciled
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div className="mt-4 flex justify-end">
                    <Button variant="outline" onClick={() => onOpenChange(false)}>
                        Close
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
