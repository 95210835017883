import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { supabase } from '@/lib/supabase';
import Logger from '@/utils/logger';
import { useToast } from "@/components/ui/use-toast";

interface ReconciliationModalProps {
    open: boolean;
    onClose: () => void;
    onReconcileComplete: () => void;
    batchNumber: string;
    storedQuantity: number;
    calculatedQuantity: number;
    isWeightBased: boolean;
    itemName: string;
    sku: string;
}

export function ReconciliationModal({
    open,
    onClose,
    onReconcileComplete,
    batchNumber,
    storedQuantity,
    calculatedQuantity,
    isWeightBased,
    itemName,
    sku,
}: ReconciliationModalProps) {
    const { toast } = useToast();
    const unit = isWeightBased ? 'kg' : 'units';
    const difference = Math.abs(calculatedQuantity - storedQuantity);
    const isNegative = storedQuantity < calculatedQuantity;

    const formatNumber = (num: number) => {
        return isWeightBased ? num.toFixed(2) : Math.round(num).toString();
    };

    const handleReconcile = async () => {
        try {
            Logger.info('Starting batch reconciliation', {
                batchNumber,
                storedQuantity,
                calculatedQuantity
            });

            // Always update available_quantity, regardless of measurement type
            const { error } = await supabase
                .from('batches')
                .update({ available_quantity: calculatedQuantity })
                .eq('batch_number', batchNumber);

            if (error) throw error;

            toast({
                title: "Balance Updated",
                description: `System balance for batch ${batchNumber} has been updated to ${formatNumber(calculatedQuantity)} ${unit}.`
            });

            onClose();
            onReconcileComplete();
        } catch (error) {
            Logger.error('Error during reconciliation', { error, batchNumber });
            toast({
                variant: "destructive",
                title: "Update Failed",
                description: "Failed to update system balance. Please try again."
            });
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle className="text-xl font-semibold">Balance Discrepancy Found</DialogTitle>
                    <DialogDescription className="text-base space-y-1">
                        <p>{itemName}</p>
                        <p className="text-sm text-gray-500">SKU: {sku} | Batch: {batchNumber}</p>
                    </DialogDescription>
                </DialogHeader>

                <div className="space-y-6 py-4">
                    <div className="bg-orange-50 border border-orange-200 rounded-lg p-4 space-y-3">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <p className="font-medium text-gray-700">Current System Balance</p>
                                <p className="text-lg">{formatNumber(storedQuantity)} {unit}</p>
                                <p className="text-sm text-gray-500">Currently recorded in system</p>
                            </div>
                            <div>
                                <p className="font-medium text-gray-700">Expected Balance</p>
                                <p className="text-lg">{formatNumber(calculatedQuantity)} {unit}</p>
                                <p className="text-sm text-gray-500">Based on transaction history</p>
                            </div>
                        </div>

                        <div className="border-t border-orange-200 pt-3">
                            <p className="font-medium text-red-600">Discrepancy</p>
                            <p className="text-lg">
                                {formatNumber(difference)} {unit} {isNegative ? 'missing from system' : 'extra in system'}
                            </p>
                        </div>
                    </div>

                    <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                        <p className="font-medium text-blue-800">About This Update</p>
                        <p className="text-sm text-blue-600 mt-1">
                            The system balance will be updated to match the expected balance of {formatNumber(calculatedQuantity)} {unit}, 
                            which has been calculated from the initial quantity minus all finalized invoice transactions. 
                            This ensures your inventory records match the actual stock movements.
                        </p>
                    </div>
                </div>

                <DialogFooter className="gap-2">
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleReconcile} className="bg-blue-600 hover:bg-blue-700">
                        Update to {formatNumber(calculatedQuantity)} {unit}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
