import React, { useState, useCallback, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { InvoiceFormValues, InvoiceStatus } from '@/types/invoice';
import { BatchSelectionDialog } from './BatchSelectionDialog';
import { supabase } from '@/lib/supabase';
import { useToast } from '@/components/ui/use-toast';
import Logger from '@/utils/logger';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from "@/components/ui/select";
import { Trash2 } from 'lucide-react';

interface InvoiceFormFieldsProps {
  form: UseFormReturn<InvoiceFormValues>;
  onDuplicateChange?: (isDuplicate: boolean) => void;
  companyOptions?: string[];
  isLoadingCompanies?: boolean;
  isEditMode?: boolean;
}

export default function InvoiceFormFields({
  form,
  onDuplicateChange,
  companyOptions = [],
  isLoadingCompanies = false,
  isEditMode,
}: InvoiceFormFieldsProps) {
  const { register, formState: { errors }, watch, setValue, trigger } = form;
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [isDuplicateInvoice, setIsDuplicateInvoice] = useState(false);
  const [skuInput, setSkuInput] = useState('');
  const [searchResults, setSearchResults] = React.useState<Array<{ sku: string; item_name: string }>>([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const { toast } = useToast();

  const items = watch('items') || [];
  const invoiceNumber = watch('invoice_number');

  // Check for duplicate invoice number
  const checkDuplicateInvoice = useCallback(async (invoice_number: string) => {
    if (!invoice_number) return;
    
    try {
      const { data, error } = await supabase
        .from('invoices')
        .select('invoice_number')
        .eq('invoice_number', invoice_number)
        .single();

      if (error && error.code !== 'PGRST116') {
        Logger.error('Error checking duplicate invoice', error);
        return;
      }

      setIsDuplicateInvoice(!!data);
      onDuplicateChange?.(!!data);
      
      if (data) {
        toast({
          title: "Duplicate Invoice",
          description: `Invoice number ${invoice_number} already exists`,
          variant: "destructive"
        });
      }
    } catch (error) {
      Logger.error('Error checking duplicate invoice', error);
    }
  }, [toast, onDuplicateChange]);

  // Watch for invoice number changes
  useEffect(() => {
    if (isEditMode) return; // Skip duplicate check in edit mode
    
    const debounceTimer = setTimeout(() => {
      if (invoiceNumber) {
        checkDuplicateInvoice(invoiceNumber);
      }
    }, 500); // Debounce for 500ms

    return () => clearTimeout(debounceTimer);
  }, [invoiceNumber, checkDuplicateInvoice, isEditMode]);

  const handleBatchSelect = async (selectedBatches: Array<{
    batch_number: string;
    quantity?: number;
    weight_kilos?: number;
    warehouse: string;
    available_quantity?: number;
    available_weight?: number;
  }>) => {
    if (currentItemIndex === null || !selectedBatches.length) return;

    Logger.info('Handling batch selection', { selectedBatches, currentItemIndex });

    try {
      const currentItem = items[currentItemIndex];
      const isWeightBased = currentItem.is_weight_based;
      
      const totalAmount = selectedBatches.reduce((sum, batch) => {
        return sum + (isWeightBased ? (batch.weight_kilos || 0) : (batch.quantity || 0));
      }, 0);

      Logger.info('Calculated total amount', { totalAmount });
      
      const newItems = [...items];
      newItems[currentItemIndex] = {
        ...newItems[currentItemIndex],
        batch_number: selectedBatches.map(b => b.batch_number).join(', '),
        warehouse: selectedBatches.map(b => b.warehouse).join(', '),
        ...(isWeightBased 
          ? { weight_kilos: totalAmount, quantity: undefined }
          : { quantity: totalAmount, weight_kilos: undefined }
        ),
        selected_batches: selectedBatches
      };
      
      setValue('items', newItems);
      await trigger(`items.${currentItemIndex}`);
      setOpenBatchDialog(false);
      setCurrentItemIndex(null);
    } catch (error) {
      Logger.error('Batch selection error', error);
      toast({
        title: "Error",
        description: "Failed to update selected batches",
        variant: "destructive"
      });
    }
  };

  const addNewItem = async () => {
    Logger.info('Adding new item to form');
    const newItems = [...items, {
      sku: '',
      item_name: '',
      batch_number: '',
      warehouse: '',
      quantity: undefined,
      weight_kilos: undefined,
      measurement_type: 'quantity',
      is_weight_based: false,
      selected_batches: []
    }];
    setValue('items', newItems);
    await trigger('items');
  };

  const removeItem = async (index: number) => {
    Logger.info('Removing item', { index });
    const newItems = [...items];
    newItems.splice(index, 1);
    setValue('items', newItems);
    await trigger('items');
  };

  const searchItems = async (term: string) => {
    if (!term) {
      setSearchResults([]);
      return;
    }

    try {
      const { data, error } = await supabase
        .from('inventory')
        .select('sku, item_name')
        .ilike('item_name', `%${term}%`)
        .limit(5);

      if (error) throw error;
      setSearchResults(data || []);
    } catch (error) {
      console.error('Error searching items:', error);
      setSearchResults([]);
    }
  };

  const lookupSku = async (sku: string, index: number) => {
    if (!sku) return;

    Logger.info('Starting SKU validation', { sku, index });
    setIsValidating(true);

    try {
      const { data: inventoryData, error: inventoryError } = await supabase
        .from('inventory')
        .select('*')
        .eq('sku', sku)
        .single();

      if (inventoryError || !inventoryData) {
        toast({
          title: "Invalid SKU",
          description: `SKU ${sku} not found`,
          variant: "destructive"
        });
        return;
      }

      Logger.info('Found inventory item', inventoryData);

      const { data: batchData, error: batchError } = await supabase
        .from('batches')
        .select('*')
        .eq('sku', sku)
        .order('expiry_date', { ascending: true });

      if (batchError) throw batchError;

      const availableBatches = (batchData || []).filter(batch => {
        if (inventoryData.is_weight_based) {
          return (batch.weight_kilos || 0) > 0;
        } else {
          return ((batch.available_quantity ?? batch.quantity) || 0) > 0;
        }
      });

      Logger.info('Available batches', { count: availableBatches.length, sku });

      if (availableBatches.length === 0) {
        toast({
          title: "No Stock",
          description: `No available stock found for SKU: ${sku}`,
          variant: "destructive"
        });
        return;
      }

      const newItems = [...items];
      newItems[index] = {
        ...newItems[index],
        sku: sku,
        item_name: inventoryData.item_name,
        is_weight_based: inventoryData.is_weight_based,
        measurement_type: inventoryData.is_weight_based ? 'weight' : 'quantity',
        quantity: inventoryData.is_weight_based ? undefined : 0,
        weight_kilos: inventoryData.is_weight_based ? 0 : undefined
      };

      setValue('items', newItems);
      await trigger(`items.${index}`);
      setCurrentItemIndex(index);
      setOpenBatchDialog(true);

    } catch (error) {
      Logger.error('Validation error', error);
      toast({
        title: "Error",
        description: "Failed to validate SKU",
        variant: "destructive"
      });
    } finally {
      setIsValidating(false);
    }
  };

  const handleQuantityChange = async (index: number, newQuantity: number) => {
    Logger.info('Updating item quantity', { index, newQuantity });
    const item = items[index];
    if (!item?.sku) return;

    const currentQuantity = item.measurement_type === 'weight' ? item.weight_kilos : item.quantity;
    const selectedBatch = item.selected_batches?.[0];
    if (!selectedBatch) return;

    const batchAvailable = item.measurement_type === 'weight' 
      ? selectedBatch.available_weight 
      : selectedBatch.available_quantity;

    const totalAvailable = (batchAvailable || 0) + (currentQuantity || 0);

    Logger.info('Quantity validation', { 
      currentQuantity, 
      batchAvailable, 
      totalAvailable,
      newQuantity 
    });

    // Only validate if we're increasing the quantity
    if (newQuantity > (currentQuantity || 0) && newQuantity > totalAvailable) {
      toast({
        variant: "destructive",
        title: "Invalid Quantity",
        description: `Maximum available quantity is ${totalAvailable}`
      });
      return;
    }

    const updatedItems = [...items];
    if (item.measurement_type === 'weight') {
      updatedItems[index] = {
        ...item,
        weight_kilos: newQuantity,
        selected_batches: [{
          ...selectedBatch,
          weight_kilos: newQuantity
        }]
      };
    } else {
      updatedItems[index] = {
        ...item,
        quantity: newQuantity,
        selected_batches: [{
          ...selectedBatch,
          quantity: newQuantity
        }]
      };
    }
    setValue('items', updatedItems);
  };

  return (
    <div>
      {isDuplicateInvoice && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
          Warning: This invoice number already exists
        </div>
      )}
      <div className="space-y-4">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Invoice Number *
            </label>
            <input
              {...register('invoice_number')}
              disabled={isEditMode}
              className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                isEditMode ? 'bg-gray-100 text-gray-500' : 'focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
              }`}
            />
            {errors.invoice_number && (
              <p className="mt-1 text-sm text-red-600">{errors.invoice_number.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date *
            </label>
            <input
              type="date"
              {...register('date')}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {errors.date && (
              <p className="mt-1 text-sm text-red-600">{errors.date.message}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Status
            </label>
            <Select
              value={form.watch('status')}
              onValueChange={(value: InvoiceStatus) => form.setValue('status', value)}
              defaultValue="final"
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="draft">Draft</SelectItem>
                <SelectItem value="final">Final</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company *
          </label>
          <div className="relative">
            <input
              placeholder="Enter company name"
              {...register("company")}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {(companyOptions.length > 0 || isLoadingCompanies) && form.getValues('company') !== companyOptions[0] && (
              <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                {isLoadingCompanies ? (
                  <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
                ) : (
                  companyOptions.map((company, index) => (
                    <button
                      key={index}
                      type="button"
                      className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                      onClick={() => {
                        form.setValue('company', company);
                        form.trigger('company');
                      }}
                    >
                      {company}
                    </button>
                  ))
                )}
              </div>
            )}
          </div>
          {errors.company && (
            <p className="mt-1 text-sm text-red-600">{errors.company.message}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Notes
          </label>
          <textarea
            {...register('notes')}
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <h3 className="text-sm font-medium mb-2">Items</h3>
          <div className="space-y-2">
            {items.map((item, index) => (
              <div key={index} className="flex items-center gap-4 p-4 bg-white border rounded-lg shadow-sm">
                <div className="flex-1">
                  <div className="font-medium">{item.item_name || 'Select SKU'}</div>
                  <div className="text-sm text-gray-500">
                    SKU: {item.sku || '-'} | Batch: {item.batch_number || '-'}
                  </div>
                  {item.warehouse && (
                    <div className="text-sm text-gray-500">
                      Location: {item.warehouse}
                    </div>
                  )}
                </div>
                
                {item.sku && (
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => removeItem(index)}
                      className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                      title="Remove item"
                    >
                      <Trash2 className="h-4 w-4" />
                    </button>
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      value={item.measurement_type === 'weight' ? item.weight_kilos : item.quantity}
                      onChange={(e) => {
                        const value = e.target.value === '' ? 0 : Number(e.target.value);
                        if (!isNaN(value)) {
                          handleQuantityChange(index, value);
                        }
                      }}
                      className="w-24 px-2 py-1 border rounded [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                    <span className="text-sm text-gray-500">
                      {item.measurement_type === 'weight' ? 'kg' : 'units'}
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="mt-2 grid grid-cols-1 gap-4">
            <div className="space-y-2">
              <div className="relative">
                <input
                  placeholder="✨ Type to search items..."
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    searchItems(e.target.value);
                  }}
                  className="w-full px-8 py-2 bg-amber-50 hover:bg-amber-100 focus:bg-amber-100 transition-colors duration-200 border border-amber-200 rounded-md shadow-sm animate-pulse-gentle placeholder:text-amber-700"
                />
                <span className="absolute left-2 top-1/2 -translate-y-1/2">
                  🔍
                </span>
                {searchResults.length > 0 && searchTerm && (
                  <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
                    {searchResults.map((item) => (
                      <div
                        key={item.sku}
                        className="px-4 py-2 hover:bg-amber-50 cursor-pointer flex justify-between"
                        onClick={() => {
                          setSkuInput(item.sku);
                          const isDuplicate = items.some(existingItem => 
                            existingItem.sku === item.sku && existingItem.selected_batches?.length > 0
                          );
                          
                          if (isDuplicate) {
                            toast({
                              title: "Duplicate SKU",
                              description: "This SKU is already in the invoice",
                              variant: "destructive"
                            });
                            return;
                          }

                          const newItems = [...items];
                          const currentIndex = items.length - 1;
                          if (currentIndex < 0 || newItems[currentIndex].selected_batches?.length > 0) {
                            newItems.push({
                              sku: item.sku,
                              batch_number: '',
                              warehouse: '',
                              quantity: 0,
                              weight_kilos: 0,
                              selected_batches: []
                            });
                          } else {
                            newItems[currentIndex] = { ...newItems[currentIndex], sku: item.sku };
                          }
                          setValue('items', newItems);
                          lookupSku(item.sku, newItems.length - 1);
                          setSearchTerm('');
                          setSearchResults([]);
                        }}
                      >
                        <span>{item.item_name}</span>
                        <span className="text-gray-500">{item.sku}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <input
                value={skuInput}
                onChange={(e) => {
                  const newSku = e.target.value;
                  setSkuInput(newSku);
                  
                  const isDuplicate = items.some(item => 
                    item.sku === newSku && item.selected_batches?.length > 0
                  );
                  
                  if (isDuplicate) {
                    toast({
                      title: "Duplicate SKU",
                      description: "This SKU is already in the invoice",
                      variant: "destructive"
                    });
                    return;
                  }

                  const newItems = [...items];
                  const currentIndex = items.length - 1;
                  if (currentIndex < 0 || newItems[currentIndex].selected_batches?.length > 0) {
                    newItems.push({
                      sku: newSku,
                      batch_number: '',
                      warehouse: '',
                      quantity: 0,
                      weight_kilos: 0,
                      selected_batches: []
                    });
                  } else {
                    newItems[currentIndex] = { ...newItems[currentIndex], sku: newSku };
                  }
                  setValue('items', newItems);
                }}
                onBlur={() => {
                  if (skuInput) {
                    const newItems = [...items];
                    const currentIndex = items.length - 1;
                    if (currentIndex >= 0) {
                      newItems[currentIndex] = { ...newItems[currentIndex], sku: skuInput };
                      setValue('items', newItems);
                      lookupSku(skuInput, currentIndex);
                      setSkuInput('');
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && skuInput) {
                    const newItems = [...items];
                    const currentIndex = items.length - 1;
                    if (currentIndex >= 0) {
                      newItems[currentIndex] = { ...newItems[currentIndex], sku: skuInput };
                      setValue('items', newItems);
                      lookupSku(skuInput, currentIndex);
                      setSkuInput('');
                    }
                  }
                }}
                disabled={isValidating}
                placeholder="Enter SKU to add item"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>
      </div>
      <BatchSelectionDialog
        open={openBatchDialog}
        onClose={() => {
          setOpenBatchDialog(false);
          setCurrentItemIndex(null);
        }}
        onSelect={handleBatchSelect}
        sku={currentItemIndex !== null ? items[currentItemIndex]?.sku : ''}
      />
    </div>
  );
}