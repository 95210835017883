import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { InventoryTable } from "@/components/inventory/InventoryTable";
import { MobileHeader } from "@/components/layout/MobileHeader";
import type { InventoryItem } from "@/types/inventory";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { EditInventoryDialog } from "@/components/inventory/EditInventoryDialog";
import { AddProductDialog } from "@/components/inventory/AddProductDialog";
import { ImportInventoryButton } from "@/components/inventory/ImportInventoryButton";
import { ExportInventoryButton } from "@/components/inventory/ExportInventoryButton";

const MasterInventory = () => {
  const [filterText, setFilterText] = useState("");
  
  const { data: inventory, refetch } = useQuery({
    queryKey: ['inventory'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('*')
        .order('item_name');
      
      if (error) throw error;
      return data as InventoryItem[];
    },
  });

  // Get available quantity from batches
  const { data: batches } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('batches')
        .select('*');
      
      if (error) throw error;
      return data;
    },
  });

  // Calculate available quantities
  const availableQuantities = React.useMemo(() => {
    if (!batches) return {};
    return batches.reduce((acc, batch) => {
      if (!acc[batch.sku]) acc[batch.sku] = 0;
      acc[batch.sku] += (batch.available_quantity || 0);
      return acc;
    }, {} as Record<string, number>);
  }, [batches]);

  const filteredInventory = inventory?.filter(item => 
    item.sku.toLowerCase().includes(filterText.toLowerCase()) ||
    item.item_name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="bg-gray-50">
      {/* Mobile View */}
      <div className="lg:hidden">
        <MobileHeader />
        <div className="fixed top-14 left-0 right-0 bg-white border-b z-10">
          <div className="px-4 py-2">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
              <Input
                type="text"
                placeholder="Search by SKU or name..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="pl-9"
              />
            </div>
          </div>
        </div>

        <div className="mt-[48px]">
          {filteredInventory?.map((item) => (
            <div key={item.sku} className="px-4 py-3 border-b bg-white">
              <div className="flex justify-between gap-2">
                <h3 className="font-medium text-gray-900 leading-tight">
                  {item.item_name}
                </h3>
                <span className="text-sm text-gray-500 flex-shrink-0">{item.sku}</span>
              </div>
              <div className="mt-1 flex justify-between items-center">
                <div>
                  <span className="text-sm text-blue-600">Available: </span>
                  <span className="text-sm text-gray-900">{availableQuantities[item.sku] || 0}</span>
                </div>
                <EditInventoryDialog 
                  item={item} 
                  onItemUpdated={refetch}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block min-h-screen">
        <div className="flex items-center gap-3 mb-2">
          <h1 className="text-2xl font-bold">Master Inventory</h1>
          <Badge variant="secondary" className="h-7 px-3">
            {inventory?.length || 0} items
          </Badge>
        </div>

        <div className="mb-8 flex items-center justify-between">
          <div className="flex-1 max-w-sm">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <Input
                placeholder="Search by SKU or name..."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <ImportInventoryButton onImportComplete={refetch} />
            <ExportInventoryButton />
            <AddProductDialog onProductAdded={refetch} />
          </div>
        </div>

        <InventoryTable inventory={filteredInventory || []} onItemUpdated={refetch} />
      </div>
    </div>
  );
};

export default MasterInventory;