import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { supabase } from '@/lib/supabase';
import { useToast } from '@/components/ui/use-toast';
import Logger from '@/utils/logger';
import { Trash2 } from 'lucide-react';

interface BatchData {
  batch_number: string;
  sku: string;
  quantity: number | null;
  weight_kilos: number | null;
  expiry_date: string;
  date_received: string;
  purchase_cost: number;
  warehouse: string;
  available_quantity: number | null;
}

interface BatchSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (selectedBatches: Array<{
    batch_number: string;
    quantity?: number;
    weight_kilos?: number;
    warehouse: string;
    available_quantity?: number;
    available_weight?: number;
  }>) => void;
  sku: string;
}

export function BatchSelectionDialog({ open, onClose, onSelect, sku }: BatchSelectionDialogProps) {
  const [batches, setBatches] = useState<BatchData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuantities, setSelectedQuantities] = useState<Record<string, string>>({});
  const [isWeightBased, setIsWeightBased] = useState(false);
  const [itemName, setItemName] = useState('');
  const { toast } = useToast();

  useEffect(() => {
    if (open && sku) {
      fetchBatches();
    } else {
      setBatches([]);
      setSelectedQuantities({});
    }
  }, [open, sku]);

  const fetchBatches = async () => {
    setLoading(true);
    try {
      const { data: inventoryData, error: inventoryError } = await supabase
        .from('inventory')
        .select('*')
        .eq('sku', sku)
        .single();

      if (inventoryError) throw inventoryError;
      if (!inventoryData) throw new Error('SKU not found');

      setIsWeightBased(inventoryData.is_weight_based);
      setItemName(inventoryData.item_name);
      Logger.info('Found inventory item', inventoryData);

      const { data, error } = await supabase
        .from('batches')
        .select('*')
        .eq('sku', sku)
        .order('expiry_date', { ascending: true });

      if (error) throw error;
      if (!data?.length) {
        toast({ title: "No Batches", description: `No batches found for SKU: ${sku}` });
        return;
      }

      const availableBatches = data.filter(batch => {
        const availableAmount = batch.available_quantity ?? 
          (inventoryData.is_weight_based ? batch.weight_kilos : batch.quantity);
        return availableAmount > 0;
      });

      setBatches(availableBatches);
      Logger.info('Available batches loaded', { count: availableBatches.length });
    } catch (err) {
      Logger.error('Error in fetchBatches', err);
      toast({
        title: "Error",
        description: "Failed to load batches",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    const selectedBatches = Object.entries(selectedQuantities)
      .filter(([_, amount]) => amount && parseFloat(amount) > 0)
      .map(([batchNumber, amount]) => {
        const batch = batches.find(b => b.batch_number === batchNumber);
        if (!batch) return null;

        const amountNum = parseFloat(amount);
        const maxAmount = isWeightBased 
          ? (batch.available_quantity ?? (batch.weight_kilos || 0))
          : (batch.available_quantity ?? (batch.quantity || 0));

        if (amountNum > maxAmount) {
          toast({
            title: "Invalid Amount",
            description: `Maximum available for batch ${batchNumber}: ${maxAmount}${isWeightBased ? ' kg' : ''}`
          });
          return null;
        }

        return {
          batch_number: batch.batch_number,
          ...(isWeightBased ? { weight_kilos: amountNum } : { quantity: amountNum }),
          warehouse: batch.warehouse,
          ...(isWeightBased 
            ? { available_weight: batch.available_quantity ?? batch.weight_kilos }
            : { available_quantity: batch.available_quantity ?? batch.quantity }
          )
        };
      })
      .filter((batch): batch is NonNullable<typeof batch> => batch !== null);

    if (selectedBatches.length === 0) {
      toast({
        title: "No Batches Selected",
        description: "Please enter valid amounts for at least one batch"
      });
      return;
    }

    onSelect(selectedBatches);
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogTitle>Select Batches for SKU: {sku}</DialogTitle>
        <div className="text-sm text-gray-500 mb-4">
          Enter amounts for the batches you want to use
        </div>

        {loading ? (
          <div className="flex justify-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
          </div>
        ) : (
          <div className="space-y-4">
            {batches.map((batch) => {
              const availableAmount = batch.available_quantity ?? 
                (isWeightBased ? batch.weight_kilos : batch.quantity);

              return (
                <div key={batch.batch_number} className="bg-white border border-gray-200 p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex items-center justify-between mb-2">
                    <div className="font-semibold text-lg text-gray-900">{itemName}</div>
                  </div>
                  <div className="space-y-1.5 text-sm text-gray-600">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Batch:</span>
                        <span>{batch.batch_number}</span>
                      </div>
                      <div className="text-sm font-medium text-gray-600">
                        Available: {availableAmount} {isWeightBased ? 'kg' : ''}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Location:</span>
                      <span>{batch.warehouse}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium">Expires:</span>
                      <span>{new Date(batch.expiry_date).toLocaleDateString()}</span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      {isWeightBased ? "Weight (kg)" : "Quantity"}
                    </label>
                    <div className="flex items-center gap-2">
                      <Input
                        type="number"
                        value={selectedQuantities[batch.batch_number] || ''}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || (isWeightBased ? /^\d*\.?\d*$/.test(value) : /^\d*$/.test(value))) {
                            setSelectedQuantities(prev => ({
                              ...prev,
                              [batch.batch_number]: value
                            }));
                          }
                        }}
                        className="w-32 border-gray-300 focus:border-blue-500 focus:ring-blue-500 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                        placeholder="Enter amount"
                      />
                      <button
                        onClick={() => {
                          setSelectedQuantities(prev => {
                            const newQuantities = { ...prev };
                            delete newQuantities[batch.batch_number];
                            return newQuantities;
                          });
                        }}
                        className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                        title="Remove batch"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <DialogFooter className="flex justify-end gap-2 mt-6">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            disabled={Object.values(selectedQuantities).every(amount => !amount || parseFloat(amount) <= 0)}
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}