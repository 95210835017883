import { Dialog, DialogContent } from "@/components/ui/dialog"
import { Loader2 } from "lucide-react"

interface ImportLoadingModalProps {
  isOpen: boolean
}

export function ImportLoadingModal({ isOpen }: ImportLoadingModalProps) {
  return (
    <Dialog open={isOpen} modal={true}>
      <DialogContent className="sm:max-w-md">
        <div className="flex flex-col items-center justify-center gap-4 py-8">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <div className="text-center">
            <h3 className="font-semibold text-lg">Importing Inventory</h3>
            <p className="text-sm text-muted-foreground mt-1">
              Please wait while we process your CSV file...
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
} 