import React, { useState } from 'react';
import { Card } from "@/components/ui/card";
import { Invoice } from '@/types/invoice';
import { ViewInvoiceDialog } from './ViewInvoiceDialog';
import { EditInvoiceDialog } from './EditInvoiceDialog';
import { Eye, Trash2 } from "lucide-react";
import { useAuth } from '@/contexts/AuthContext';
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import Logger from "@/utils/logger";

interface MobileInvoicesViewProps {
  invoices: Invoice[];
  onDelete: () => Promise<void>;
}

export function MobileInvoicesView({ invoices, onDelete }: MobileInvoicesViewProps) {
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const { canEdit } = useAuth();
  const { toast } = useToast();

  const handleDelete = async (invoice: Invoice) => {
    try {
      const { error } = await supabase
        .from('invoices')
        .delete()
        .eq('id', invoice.id);

      if (error) throw error;

      toast({
        title: "Invoice deleted",
        description: `Invoice #${invoice.invoice_number} has been deleted.`,
      });

      await onDelete();
    } catch (error) {
      Logger.error('Error deleting invoice:', error);
      toast({
        title: "Error",
        description: "Failed to delete invoice. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      {invoices.map((invoice) => (
        <Card key={invoice.id} className="p-4 bg-white">
          <div className="flex justify-between items-start mb-2">
            <div>
              <h3 className="font-medium text-gray-900">#{invoice.invoice_number}</h3>
              <p className="text-sm text-gray-500">{invoice.company}</p>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setSelectedInvoice(invoice)}
                className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50"
                title="View Invoice"
              >
                <Eye className="h-4 w-4" />
              </button>
              {canEdit && (
                <>
                  <EditInvoiceDialog 
                    invoice={invoice} 
                    onInvoiceUpdated={onDelete}
                  />
                  <button
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this invoice?')) {
                        handleDelete(invoice);
                      }
                    }}
                    className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                    title="Delete Invoice"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-2 text-sm">
            <div>
              <span className="text-gray-500">Date: </span>
              <span className="text-gray-900">
                {new Date(invoice.date).toLocaleDateString()}
              </span>
            </div>
            <div>
              <span className="text-gray-500">Status: </span>
              <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                invoice.status === 'draft' ? 'bg-gray-100 text-gray-800' : 'bg-green-100 text-green-800'
              }`}>
                {invoice.status}
              </span>
            </div>
            <div>
              <span className="text-gray-500">Items: </span>
              <span className="text-gray-900">{invoice.items?.length || 0}</span>
            </div>
            <div>
              <span className="text-gray-500">Total: </span>
              <span className="text-gray-900">${invoice.total_amount?.toFixed(2) || '0.00'}</span>
            </div>
          </div>
        </Card>
      ))}

      <ViewInvoiceDialog
        open={!!selectedInvoice}
        onClose={() => setSelectedInvoice(null)}
        invoice={selectedInvoice}
      />
    </div>
  );
}
