import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import InvoiceFormFields from "./InvoiceFormFields";
import { invoiceFormSchema, type InvoiceFormValues } from "@/types/invoice";
import Logger from "@/utils/logger";
import { useAuth } from '@/contexts/AuthContext';

interface AddInvoiceDialogProps {
  onInvoiceAdded: () => void;
}

export function AddInvoiceDialog({ onInvoiceAdded }: AddInvoiceDialogProps) {
  const [open, setOpen] = useState(false);
  const [isDuplicateInvoice, setIsDuplicateInvoice] = useState(false);
  const { toast } = useToast();
  const { canEdit } = useAuth();

  const form = useForm<InvoiceFormValues>({
    resolver: zodResolver(invoiceFormSchema),
    defaultValues: {
      date: new Date().toISOString().split("T")[0],
      invoice_number: "",
      company: "",
      notes: "",
      status: "final",
      items: [],
    },
  });

  const [companyOptions, setCompanyOptions] = useState<string[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  // Fetch existing companies for autocomplete
  const fetchCompanies = useCallback(async (searchTerm: string) => {
    setIsLoadingCompanies(true);
    try {
      const { data, error } = await supabase
        .from('invoices')
        .select('company')
        .ilike('company', `${searchTerm}%`)
        .order('company')
        .limit(5);

      if (error) throw error;

      const uniqueCompanies = Array.from(new Set(data.map(d => d.company))).filter(Boolean);
      setCompanyOptions(uniqueCompanies);
    } catch (error) {
      Logger.error('Error fetching companies', error);
    } finally {
      setIsLoadingCompanies(false);
    }
  }, []);

  // Debounce company search
  useEffect(() => {
    const company = form.watch('company');
    if (company) {
      const timer = setTimeout(() => fetchCompanies(company), 300);
      return () => clearTimeout(timer);
    } else {
      setCompanyOptions([]);
    }
  }, [form.watch('company'), fetchCompanies]);

  const resetForm = useCallback(() => {
    form.reset({
      date: new Date().toISOString().split("T")[0],
      invoice_number: "",
      company: "",
      notes: "",
      status: "final",
      items: [],
    });
  }, [form]);

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      resetForm();
    }
    setOpen(newOpen);
  };

  const validateForm = (data: InvoiceFormValues) => {
    if (!data.items?.length) {
      toast({
        title: "Validation Error",
        description: "Please add at least one item to the invoice",
        variant: "destructive",
      });
      return false;
    }

    for (const item of data.items) {
      if (!item.sku || !item.selected_batches?.length) {
        toast({
          title: "Validation Error",
          description: "Please complete all item details including SKU and batch selection",
          variant: "destructive",
        });
        return false;
      }
    }

    return true;
  };

  const onSubmit = async (values: InvoiceFormValues) => {
    try {
      Logger.info("Submitting invoice form", { values });

      if (!validateForm(values)) {
        return;
      }

      // Create invoice
      const { data: invoice, error: invoiceError } = await supabase
        .from("invoices")
        .insert({
          invoice_number: values.invoice_number,
          date: values.date,
          company: values.company,
          notes: values.notes || "",
          status: values.status,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        })
        .select()
        .single();

      if (invoiceError) {
        Logger.error("Error creating invoice", { error: invoiceError });
        throw new Error(invoiceError.message);
      }

      if (!invoice) {
        throw new Error("Failed to create invoice");
      }

      // Create invoice items
      for (const item of values.items) {
        if (!item.selected_batches) continue;

        const invoiceItems = item.selected_batches.map((batch) => ({
          invoice_id: invoice.id,
          sku: item.sku,
          quantity: item.is_weight_based ? null : batch.quantity,
          weight_kilos: item.is_weight_based ? batch.weight_kilos : null,
          batch_number: batch.batch_number,
          warehouse: batch.warehouse,
          measurement_type: item.is_weight_based ? "weight" : "quantity",
          unit_price: item.unit_price || null,
        }));

        const { error: itemsError } = await supabase
          .from("invoice_items")
          .insert(invoiceItems);

        if (itemsError) {
          Logger.error("Error creating invoice items", { error: itemsError });
          // Rollback invoice
          await supabase.from("invoices").delete().eq("id", invoice.id);
          throw new Error(itemsError.message);
        }
      }

      // Update batch quantities - moved outside the invoice items loop
      if (values.status === "final") {
        for (const item of values.items) {
          if (!item.selected_batches) continue;
          
          for (const batch of item.selected_batches) {
            // Get current batch state
            const { data: currentBatch, error: fetchError } = await supabase
              .from("batches")
              .select("quantity, available_quantity, weight_kilos")
              .eq("batch_number", batch.batch_number)
              .eq("sku", item.sku)
              .single();

            if (fetchError || !currentBatch) {
              Logger.error("Error fetching batch", { batch: batch.batch_number, error: fetchError });
              // Rollback everything
              await supabase.from("invoice_items").delete().eq("invoice_id", invoice.id);
              await supabase.from("invoices").delete().eq("id", invoice.id);
              throw new Error(`Failed to fetch batch ${batch.batch_number}`);
            }

            const currentAvailable = currentBatch.available_quantity ?? 
              (item.is_weight_based ? currentBatch.weight_kilos : currentBatch.quantity) ?? 0;
            
            const deductAmount = item.is_weight_based 
              ? (batch.weight_kilos ?? 0) 
              : (batch.quantity ?? 0);

            const newAvailableQuantity = Math.max(0, currentAvailable - deductAmount);

            Logger.info('Updating batch quantity', {
              batch: batch.batch_number,
              currentAvailable,
              deductAmount,
              newAvailableQuantity
            });

            const { error: updateError } = await supabase
              .from("batches")
              .update({
                available_quantity: newAvailableQuantity
              })
              .eq("batch_number", batch.batch_number)
              .eq("sku", item.sku);

            if (updateError) {
              Logger.error("Error updating batch", { batch: batch.batch_number, error: updateError });
              // Rollback everything
              await supabase.from("invoice_items").delete().eq("invoice_id", invoice.id);
              await supabase.from("invoices").delete().eq("id", invoice.id);
              throw new Error(`Failed to update batch ${batch.batch_number}`);
            }
          }
        }
      }

      toast({
        title: "Success",
        description: "Invoice created successfully",
      });

      setOpen(false);
      resetForm();
      onInvoiceAdded?.();
    } catch (error) {
      Logger.error("Failed to create invoice", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to create invoice",
        variant: "destructive",
      });
    }
  };

  if (!canEdit) return null;

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button onClick={() => {
          resetForm();
          setOpen(true);
        }}>
          <Plus className="w-4 h-4 mr-2" />
          Create Invoice
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto bg-white">
        <DialogHeader className="bg-gray-50 p-4 rounded-t-lg sticky top-0 z-10">
          <DialogTitle className="text-gray-900">Create New Invoice</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <InvoiceFormFields form={form} onDuplicateChange={setIsDuplicateInvoice} companyOptions={companyOptions} isLoadingCompanies={isLoadingCompanies} />
            <div className="flex justify-end space-x-2">
              <Button
                type="submit"
                disabled={isDuplicateInvoice}
                className={isDuplicateInvoice ? "opacity-50 cursor-not-allowed" : ""}
              >
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}