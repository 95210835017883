import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';

export function useUserEmail() {
  const [userEmail, setUserEmail] = useState<string>('');

  useEffect(() => {
    async function getUserEmail() {
      const { data: { user } } = await supabase.auth.getUser();
      if (user?.email) {
        setUserEmail(user.email);
      }
    }
    getUserEmail();
  }, []);

  return userEmail;
}
