import { z } from "zod";

// Define VAT rates as literal type
export type VATRate = 8.1 | 2.6;

export interface InventoryItem {
  sku: string;
  item_name: string;
  description?: string;
  vat_rate: number;
  is_weight_based: boolean;
}

// Create a Zod schema for VAT rates
const vatRateSchema = z.union([
  z.literal(8.1),
  z.literal(2.6)
]);

export const formSchema = z.object({
  sku: z.string().min(1, "SKU is required"),
  item_name: z.string().min(1, "Name is required"),
  description: z.string().optional(),
  vat_rate: vatRateSchema,
  is_weight_based: z.boolean()
});