import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { BatchesTable } from "@/components/batches/BatchesTable";
import { MobileBatchesView } from "@/components/batches/MobileBatchesView";
import type { Batch } from "@/types/batch";
import { Badge } from "@/components/ui/badge";
import { AddBatchDialog } from "@/components/batches/AddBatchDialog";

const Batches = () => {
  const [filterText, setFilterText] = useState("");
  
  const { data: batches, refetch } = useQuery({
    queryKey: ['batches'],
    queryFn: async () => {
      // First get all batches with their SKUs
      const { data: batchData, error: batchError } = await supabase
        .from('batches')
        .select('*')
        .order('date_received', { ascending: false });
      
      if (batchError) throw batchError;

      // Then get all inventory items
      const { data: inventoryData, error: invError } = await supabase
        .from('inventory')
        .select('sku, item_name');

      if (invError) throw invError;

      // Create a map of SKU to item name
      const itemNameMap = new Map(inventoryData.map(item => [item.sku, item.item_name]));

      // Combine the data
      const enrichedBatches = batchData.map(batch => ({
        ...batch,
        item_name: itemNameMap.get(batch.sku) || 'Unknown Item'
      }));

      return enrichedBatches as Batch[];
    },
  });

  const filteredBatches = batches?.filter(batch => 
    batch.batch_number.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div className="bg-gray-50">
      {/* Mobile View */}
      <MobileBatchesView 
        batches={filteredBatches || []}
        filterText={filterText}
        onFilterChange={setFilterText}
        onBatchUpdated={refetch}
      />

      {/* Desktop View */}
      <div className="hidden lg:block">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">Batch Management</h1>
            <p className="text-sm text-gray-600">Track and manage inventory batches</p>
          </div>
          <AddBatchDialog onBatchAdded={refetch} />
        </div>

        <BatchesTable 
          batches={filteredBatches || []} 
          onBatchUpdated={refetch}
        />
      </div>
    </div>
  );
};

export default Batches;