import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { Button } from '@/components/ui/button';
import Login from './Login';

export default function Home() {
  const [showSignIn, setShowSignIn] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (user) {
      navigate('/inventory');
    } else {
      setShowSignIn(true);
    }
  };

  return (
    <div className="min-h-screen bg-[#304661] text-white">
      {/* Header */}
      <header className="container mx-auto px-4 py-6 flex justify-center items-center">
        <img src="/chefland2.png" alt="Chefland Logo" className="h-10" />
      </header>

      {/* Hero Section */}
      <main className="container mx-auto px-4 py-20 text-center">
        <h1 className="text-6xl font-bold mb-6">
          Smart Inventory
          <span className="block text-[#99AE4C]">Management</span>
        </h1>
        <p className="text-xl mb-12 max-w-3xl mx-auto">
          A modern IMS designed especially for food service operations.
        </p>
        <Button
          onClick={handleGetStarted}
          className="bg-[#99AE4C] hover:bg-[#7B8C3D] text-white px-8 py-6 text-lg rounded-md"
        >
          Get Started
        </Button>
      </main>

      {/* Features Grid */}
      <section className="container mx-auto px-4 py-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="bg-[#3D5366] p-8 rounded-lg">
          <div className="text-[#99AE4C] mb-4">
            <svg className="w-16 h-16 mx-auto" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* Real-time Dashboard */}
              <rect x="8" y="8" width="48" height="48" rx="8" fill="#2C3E50"/>
              <path d="M16 44L26 32L36 38L48 20" stroke="#99AE4C" strokeWidth="3" strokeLinecap="round"/>
              <circle cx="26" cy="32" r="3" fill="#99AE4C"/>
              <circle cx="36" cy="38" r="3" fill="#99AE4C"/>
              <circle cx="48" cy="20" r="3" fill="#99AE4C">
                <animate attributeName="opacity" values="1;0.3;1" dur="1.5s" repeatCount="indefinite"/>
              </circle>
              <path d="M16 20h8M16 28h6" stroke="#99AE4C" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
          <h3 className="text-xl font-bold mb-4">Real-time Tracking</h3>
          <p className="text-gray-300">
            Monitor your inventory levels and movements as they happen.
          </p>
        </div>

        <div className="bg-[#3D5366] p-8 rounded-lg">
          <div className="text-[#99AE4C] mb-4">
            <svg className="w-16 h-16 mx-auto" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* Modern Warehouse */}
              <path d="M8 52h48M12 52V28l20-12 20 12v24" stroke="#99AE4C" strokeWidth="2"/>
              <rect x="16" y="32" width="12" height="20" rx="1" stroke="#99AE4C" strokeWidth="2"/>
              <rect x="36" y="32" width="12" height="20" rx="1" stroke="#99AE4C" strokeWidth="2"/>
              <path d="M22 38h4M22 44h4M42 38h4M42 44h4" stroke="#99AE4C" strokeWidth="2" strokeLinecap="round"/>
              <path d="M32 12l12 8-12 8-12-8z" fill="#99AE4C"/>
            </svg>
          </div>
          <h3 className="text-xl font-bold mb-4">Multi-warehouse</h3>
          <p className="text-gray-300">
            Manage multiple locations seamlessly.
          </p>
        </div>

        <div className="bg-[#3D5366] p-8 rounded-lg">
          <div className="text-[#99AE4C] mb-4">
            <svg className="w-16 h-16 mx-auto" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* Smart Batches */}
              <rect x="12" y="12" width="40" height="40" rx="4" stroke="#99AE4C" strokeWidth="2"/>
              <path d="M12 24h40M24 12v40" stroke="#99AE4C" strokeWidth="2"/>
              <rect x="28" y="28" width="20" height="8" rx="2" fill="#99AE4C"/>
              <rect x="28" y="40" width="20" height="8" rx="2" fill="#99AE4C" fillOpacity="0.5"/>
              <circle cx="16" cy="18" r="2" fill="#99AE4C"/>
              <circle cx="32" cy="18" r="2" fill="#99AE4C"/>
              <circle cx="48" cy="18" r="2" fill="#99AE4C"/>
              <path d="M14 32h6M14 44h6" stroke="#99AE4C" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
          <h3 className="text-xl font-bold mb-4">Smart Batch Control</h3>
          <p className="text-gray-300">
            Track batches including expiry dates, purchase cost & location.
          </p>
        </div>

        <div className="bg-[#3D5366] p-8 rounded-lg">
          <div className="text-[#99AE4C] mb-4">
            <svg className="w-16 h-16 mx-auto" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              {/* Automated System */}
              <circle cx="32" cy="32" r="24" stroke="#99AE4C" strokeWidth="2" strokeDasharray="4 4">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 32 32"
                  to="360 32 32"
                  dur="15s"
                  repeatCount="indefinite"
                />
              </circle>
              <circle cx="32" cy="32" r="18" stroke="#99AE4C" strokeWidth="2"/>
              <path d="M24 32l6 6 12-12" stroke="#99AE4C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              <circle cx="32" cy="32" r="3" fill="#99AE4C"/>
              <path d="M32 14v6M50 32h-6M32 50v-6M14 32h6" stroke="#99AE4C" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </div>
          <h3 className="text-xl font-bold mb-4">Automated Audit</h3>
          <p className="text-gray-300">
            Keep your inventory balances accurate with automated reconciliation and audit logs.
          </p>
        </div>
      </section>

      {/* Sign In Modal */}
      {showSignIn && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50 p-4">
          <div className="relative">
            <Login />
          </div>
        </div>
      )}
    </div>
  );
}
