import React, { useRef, useState, ChangeEvent } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Upload, FileSearch } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabase';
import Papa from 'papaparse';
import Logger from '@/utils/logger';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import { analyzeCSVFile, CSVAnalysisResult } from "@/lib/csvAnalysis";
import { AnalyseCSVDialog } from "./AnalyseCSVDialog";
import { PlusCircle, RefreshCw, MinusCircle, CheckCircle, AlertCircle } from 'lucide-react';
import { ImportLoadingModal } from "./ImportLoadingModal"
import { useAuth } from '@/contexts/AuthContext';

interface ImportInventoryButtonProps {
  onImportComplete: () => void;
}

interface ImportSummary {
  newItems: string[];
  updatedItems: string[];
  failedItems: string[];
  totalInDb: number;
}

interface DuplicateWarning {
  sku: string;
  items: {
    item_name: string;
    description: string | null;
    vat_rate: number;
    is_weight_based: boolean;
  }[];
}

interface InventoryItem {
  sku: string;
  item_name: string;
  description: string | null;
  vat_rate: number;
  is_weight_based: boolean;
}

interface ImportPreviewStats {
  currentDbCount: number;
  existingItemsCount: number;
  newItemsCount: number;
  updatedItemsCount: number;
  skippedItemsCount: number;
  expectedTotalAfterImport: number;
}

interface ImportPreview {
  stats: ImportPreviewStats;
  newItems: Array<{
    sku: string;
    item_name: string;
  }>;
  updatedItems: Array<{
    sku: string;
    item_name: string;
    changes: {
      field: string;
      oldValue: any;
      newValue: any;
    }[];
  }>;
  skippedItems: Array<{
    sku: string;
    item_name: string;
  }>;
}

export function ImportInventoryButton({ onImportComplete }: ImportInventoryButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const importFileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const { canEdit } = useAuth();
  const [showConflictsDialog, setShowConflictsDialog] = useState(false);
  const [showAnalysisDialog, setShowAnalysisDialog] = useState(false);
  const [showImportPreview, setShowImportPreview] = useState(false);
  const [importPreview, setImportPreview] = useState<ImportPreview | null>(null);
  const [pendingImportData, setPendingImportData] = useState<any[] | null>(null);
  const [analysisResult, setAnalysisResult] = useState<CSVAnalysisResult | null>(null);
  const [importSummary, setImportSummary] = useState<ImportSummary | null>(null);
  const [duplicateWarning, setDuplicateWarning] = useState<DuplicateWarning[]>([]);
  const [currentFileName, setCurrentFileName] = useState<string>('');
  const [showSkippedItems, setShowSkippedItems] = useState(false);
  const [isImporting, setIsImporting] = useState(false)

  if (!canEdit) return null;

  const normalizeValue = (value: any): string => {
    if (value === null || value === undefined) return '';
    return String(value).trim().toLowerCase();
  };

  const hasChanges = (existing: InventoryItem, newItem: InventoryItem): boolean => {
    // Normalize all values for comparison
    const existingNormalized = {
      item_name: normalizeValue(existing.item_name),
      description: normalizeValue(existing.description),
      vat_rate: Number(existing.vat_rate).toFixed(1),
      is_weight_based: existing.is_weight_based ? 'true' : 'false'
    };

    const newNormalized = {
      item_name: normalizeValue(newItem.item_name),
      description: normalizeValue(newItem.description),
      vat_rate: Number(newItem.vat_rate).toFixed(1),
      is_weight_based: newItem.is_weight_based ? 'true' : 'false'
    };

    // Compare each field
    const differences = {
      item_name: existingNormalized.item_name !== newNormalized.item_name,
      description: existingNormalized.description !== newNormalized.description,
      vat_rate: existingNormalized.vat_rate !== newNormalized.vat_rate,
      is_weight_based: existingNormalized.is_weight_based !== newNormalized.is_weight_based
    };

    const hasAnyChanges = Object.values(differences).some(changed => changed);

    // Log comparison details
    Logger.info('Item comparison', {
      sku: existing.sku,
      existing: existingNormalized,
      new: newNormalized,
      differences,
      hasChanges: hasAnyChanges
    });

    return hasAnyChanges;
  };

  const findDuplicates = (data: any[]): DuplicateWarning[] => {
    const skuMap = new Map<string, typeof data>();
    const duplicates: DuplicateWarning[] = [];

    // Group items by SKU
    data.forEach(item => {
      const sku = item.sku.toString();
      if (!skuMap.has(sku)) {
        skuMap.set(sku, [item]);
      } else {
        skuMap.get(sku)?.push(item);
      }
    });

    // Find SKUs with multiple items
    skuMap.forEach((items, sku) => {
      if (items.length > 1) {
        duplicates.push({
          sku,
          items: items.map(item => ({
            item_name: item.item_name,
            description: item.description || null,
            vat_rate: parseFloat(item.vat_rate),
            is_weight_based: String(item.is_weight_based).toLowerCase() === 'true'
          }))
        });
      }
    });

    return duplicates;
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Reset file input
    event.target.value = '';

    // Parse CSV
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        try {
          Logger.info('Parsing CSV file', { rowCount: results.data.length });

          // Validate CSV structure
          const requiredColumns = ['sku', 'item_name', 'description', 'vat_rate', 'is_weight_based'];
          const headers = Object.keys(results.data[0] || {});
          const missingColumns = requiredColumns.filter(col => !headers.includes(col));

          if (missingColumns.length > 0) {
            throw new Error(`Missing required columns: ${missingColumns.join(', ')}`);
          }

          // Check for duplicates in the CSV file itself
          const duplicates = findDuplicates(results.data);
          if (duplicates.length > 0) {
            setDuplicateWarning(duplicates);
            return;
          }

          // Process each row
          const processedData = results.data.map((row: any) => ({
            sku: row.sku.toString(),
            item_name: row.item_name,
            description: row.description || null,
            vat_rate: parseFloat(row.vat_rate),
            is_weight_based: String(row.is_weight_based).toLowerCase() === 'true'
          }));

          // Get current DB count
          const { count: currentDbCount } = await supabase
            .from('inventory')
            .select('*', { count: 'exact', head: true });

          // First, get all existing items that match our SKUs
          const skus = processedData.map(item => item.sku);
          const { data: existingItems, error: fetchError } = await supabase
            .from('inventory')
            .select('*')
            .in('sku', skus);

          if (fetchError) throw fetchError;

          // Create preview of changes
          const preview: ImportPreview = {
            stats: {
              currentDbCount: currentDbCount || 0,
              existingItemsCount: existingItems?.length || 0,
              newItemsCount: 0,
              updatedItemsCount: 0,
              skippedItemsCount: 0,
              expectedTotalAfterImport: currentDbCount || 0
            },
            newItems: [],
            updatedItems: [],
            skippedItems: []
          };

          // Create a map of existing items by SKU for easy lookup
          const existingItemMap = new Map(
            (existingItems || []).map(item => [item.sku, item])
          );

          for (const item of processedData) {
            const existingItem = existingItemMap.get(item.sku);

            if (existingItem) {
              if (hasChanges(existingItem, item)) {
                const changes = [];
                if (existingItem.item_name !== item.item_name) {
                  changes.push({
                    field: 'item_name',
                    oldValue: existingItem.item_name,
                    newValue: item.item_name
                  });
                }
                if (existingItem.description !== item.description) {
                  changes.push({
                    field: 'description',
                    oldValue: existingItem.description,
                    newValue: item.description
                  });
                }
                if (existingItem.vat_rate !== item.vat_rate) {
                  changes.push({
                    field: 'vat_rate',
                    oldValue: existingItem.vat_rate,
                    newValue: item.vat_rate
                  });
                }
                if (existingItem.is_weight_based !== item.is_weight_based) {
                  changes.push({
                    field: 'is_weight_based',
                    oldValue: existingItem.is_weight_based ? 'Yes' : 'No',
                    newValue: item.is_weight_based ? 'Yes' : 'No'
                  });
                }

                preview.updatedItems.push({
                  sku: item.sku,
                  item_name: item.item_name,
                  changes
                });
                preview.stats.updatedItemsCount++;
              } else {
                preview.skippedItems.push({
                  sku: item.sku,
                  item_name: item.item_name
                });
                preview.stats.skippedItemsCount++;
              }
            } else {
              preview.newItems.push({
                sku: item.sku,
                item_name: item.item_name
              });
              preview.stats.newItemsCount++;
            }
          }

          // Calculate expected total
          preview.stats.expectedTotalAfterImport = 
            preview.stats.currentDbCount + preview.stats.newItemsCount;

          // Store the processed data for later use
          setPendingImportData(processedData);
          setImportPreview(preview);
          setShowImportPreview(true);

        } catch (error: any) {
          Logger.error('Import preview error', error);
          toast({
            title: 'Preview Failed',
            description: error.message,
            variant: 'destructive',
          });
        }
      },
      error: (error) => {
        Logger.error('CSV parsing error', error);
        toast({
          title: 'Import Failed',
          description: 'Failed to parse CSV file',
          variant: 'destructive',
        });
      }
    });
  };

  const handleConfirmImport = async () => {
    if (!pendingImportData) return;

    try {
      setIsImporting(true)
      const summary: ImportSummary = {
        newItems: [],
        updatedItems: [],
        failedItems: [],
        totalInDb: 0
      };

      // Process the pending import data
      for (const item of pendingImportData) {
        try {
          const existingItem = (await supabase
            .from('inventory')
            .select('*')
            .eq('sku', item.sku)
            .single()).data;

          if (existingItem) {
            if (hasChanges(existingItem, item)) {
              const { error: updateError } = await supabase
                .from('inventory')
                .update({
                  item_name: item.item_name,
                  description: item.description,
                  vat_rate: item.vat_rate,
                  is_weight_based: item.is_weight_based
                })
                .eq('sku', item.sku);

              if (updateError) throw updateError;
              summary.updatedItems.push(`${item.sku} (${item.item_name})`);
            }
          } else {
            const { error: insertError } = await supabase
              .from('inventory')
              .insert([item]);

            if (insertError) throw insertError;
            summary.newItems.push(`${item.sku} (${item.item_name})`);
          }
        } catch (error) {
          Logger.error('Error processing item', { sku: item.sku, error });
          summary.failedItems.push(`${item.sku} (${item.item_name})`);
        }
      }

      // Get total count in DB
      const { count } = await supabase
        .from('inventory')
        .select('*', { count: 'exact', head: true });

      summary.totalInDb = count || 0;
      setImportSummary(summary);

      // Show completion toast
      if (summary.failedItems.length > 0) {
        toast({
          title: 'Import Partially Successful',
          description: `Added ${summary.newItems.length} items, updated ${summary.updatedItems.length} items, ${summary.failedItems.length} failed`,
          variant: 'default'
        });
      } else {
        toast({
          title: 'Import Successful',
          description: `Added ${summary.newItems.length} items, updated ${summary.updatedItems.length} items`,
        });
      }

      // Clear the preview state
      setShowImportPreview(false);
      setImportPreview(null);
      setPendingImportData(null);

      onImportComplete();
    } catch (error: any) {
      Logger.error('Import error', error);
      toast({
        title: 'Import Failed',
        description: error.message,
        variant: 'destructive',
      });
    } finally {
      setIsImporting(false)
    }
  };

  const handleAnalyseClick = () => {
    fileInputRef.current?.click();
  };

  const handleAnalyseFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const result = await analyzeCSVFile(file);
      setAnalysisResult(result);
      setCurrentFileName(file.name);
      setShowAnalysisDialog(true);
    } catch (error) {
      toast({
        title: "Error Analyzing CSV",
        description: "Failed to analyze the CSV file. Please check the file format.",
        variant: "destructive",
      });
    }

    // Clear the input so the same file can be selected again
    event.target.value = '';
  };

  return (
    <>
      <div className="flex gap-2">
        <Input
          type="file"
          accept=".csv"
          className="hidden"
          ref={fileInputRef}
          onChange={handleAnalyseFileSelect}
        />
        <Input
          type="file"
          accept=".csv"
          className="hidden"
          ref={importFileInputRef}
          onChange={handleFileSelect}
        />
        <Button
          variant="outline"
          size="sm"
          className="gap-2"
          onClick={handleAnalyseClick}
        >
          <FileSearch className="h-4 w-4" />
          Analyse CSV
        </Button>
        <Button
          variant="outline"
          size="sm"
          className="gap-2"
          onClick={() => importFileInputRef.current?.click()}
        >
          <Upload className="h-4 w-4" />
          Import CSV
        </Button>

        {/* Duplicate Warning Modal */}
        <Dialog open={duplicateWarning.length > 0} onOpenChange={() => setDuplicateWarning([])}>
          <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle className="text-red-600">Duplicate SKUs Found</DialogTitle>
            </DialogHeader>
            <div className="space-y-6">
              <p className="text-sm text-gray-600">
                The following SKUs appear multiple times in your CSV file with different values. 
                Please fix these duplicates and try importing again.
              </p>
              {duplicateWarning.map((dupe, index) => (
                <div key={index} className="border rounded-lg p-4 space-y-3">
                  <h3 className="font-medium text-red-600">SKU: {dupe.sku}</h3>
                  <div className="grid grid-cols-1 gap-3">
                    {dupe.items.map((item, itemIndex) => (
                      <div key={itemIndex} className="bg-gray-50 p-3 rounded">
                        <div className="grid grid-cols-2 gap-2 text-sm">
                          <div>
                            <span className="text-gray-500">Item Name:</span>{' '}
                            <span className="font-medium">{item.item_name}</span>
                          </div>
                          <div>
                            <span className="text-gray-500">Description:</span>{' '}
                            <span className="font-medium">{item.description || '-'}</span>
                          </div>
                          <div>
                            <span className="text-gray-500">VAT Rate:</span>{' '}
                            <span className="font-medium">{item.vat_rate}%</span>
                          </div>
                          <div>
                            <span className="text-gray-500">Weight Based:</span>{' '}
                            <span className="font-medium">{item.is_weight_based ? 'Yes' : 'No'}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setDuplicateWarning([])}>Close</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Import Preview Modal */}
        <Dialog open={showImportPreview} onOpenChange={setShowImportPreview}>
          <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold">Import Preview</DialogTitle>
              <DialogDescription>Review the changes before confirming the import</DialogDescription>
            </DialogHeader>

            {importPreview && (
              <div className="space-y-6">
                {/* Stats Dashboard */}
                <div className="grid grid-cols-3 gap-4 p-4 bg-gray-50 rounded-lg">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Current Database</p>
                    <p className="text-2xl font-semibold">{importPreview.stats.currentDbCount}</p>
                    <p className="text-xs text-gray-400">items in database</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Changes</p>
                    <div className="flex gap-2 items-baseline">
                      <span className="text-2xl font-semibold text-green-600">+{importPreview.stats.newItemsCount}</span>
                      <span className="text-sm text-gray-400">new</span>
                      <span className="text-2xl font-semibold text-blue-600 ml-2">~{importPreview.stats.updatedItemsCount}</span>
                      <span className="text-sm text-gray-400">updates</span>
                    </div>
                    <p className="text-xs text-gray-400">{importPreview.stats.skippedItemsCount} unchanged items skipped</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">After Import</p>
                    <p className="text-2xl font-semibold">{importPreview.stats.expectedTotalAfterImport}</p>
                    <p className="text-xs text-gray-400">total items expected</p>
                  </div>
                </div>

                {/* New Items Section */}
                {importPreview.newItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-green-600 flex items-center gap-2">
                      <PlusCircle className="h-4 w-4" />
                      New Items to Add ({importPreview.newItems.length})
                    </h3>
                    <div className="bg-white rounded-lg border overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">SKU</th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item Name</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {importPreview.newItems.map((item, i) => (
                            <tr key={i} className="hover:bg-gray-50">
                              <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{item.sku}</td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{item.item_name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Updated Items Section */}
                {importPreview.updatedItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-blue-600 flex items-center gap-2">
                      <RefreshCw className="h-4 w-4" />
                      Items to Update ({importPreview.updatedItems.length})
                    </h3>
                    <div className="space-y-2">
                      {importPreview.updatedItems.map((item, i) => (
                        <div key={i} className="bg-white rounded-lg border p-4">
                          <div className="flex items-center justify-between mb-2">
                            <div className="font-medium">{item.sku}</div>
                            <div className="text-sm text-gray-500">{item.item_name}</div>
                          </div>
                          <table className="min-w-full">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">Field</th>
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">Current Value</th>
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">New Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.changes.map((change, j) => (
                                <tr key={j}>
                                  <td className="px-4 py-2 text-sm font-medium text-gray-900">{change.field}</td>
                                  <td className="px-4 py-2 text-sm text-red-500">{change.oldValue}</td>
                                  <td className="px-4 py-2 text-sm text-green-500">{change.newValue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Skipped Items Section */}
                {importPreview.skippedItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-gray-500 flex items-center gap-2">
                      <MinusCircle className="h-4 w-4" />
                      Unchanged Items ({importPreview.skippedItems.length})
                    </h3>
                    <div className="bg-gray-50 rounded-lg border p-4">
                      <p className="text-sm text-gray-500">
                        {importPreview.skippedItems.length} items already exist in the database and have no changes.
                        {importPreview.skippedItems.length > 0 && (
                          <button
                            onClick={() => setShowSkippedItems(!showSkippedItems)}
                            className="ml-2 text-blue-500 hover:text-blue-600"
                          >
                            {showSkippedItems ? 'Hide details' : 'Show details'}
                          </button>
                        )}
                      </p>
                      {showSkippedItems && (
                        <div className="mt-2 max-h-32 overflow-y-auto">
                          <table className="min-w-full divide-y divide-gray-200">
                            <tbody className="divide-y divide-gray-200">
                              {importPreview.skippedItems.map((item, i) => (
                                <tr key={i}>
                                  <td className="px-4 py-2 text-sm text-gray-500">{item.sku}</td>
                                  <td className="px-4 py-2 text-sm text-gray-500">{item.item_name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(!importPreview.newItems.length && !importPreview.updatedItems.length) && (
                  <div className="text-center py-8">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100">
                      <CheckCircle className="h-6 w-6 text-gray-600" />
                    </div>
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Changes Required</h3>
                    <p className="mt-1 text-sm text-gray-500">All items are up to date in the database.</p>
                  </div>
                )}
              </div>
            )}

            <DialogFooter className="mt-6">
              <Button variant="outline" onClick={() => setShowImportPreview(false)}>Cancel</Button>
              <Button 
                onClick={handleConfirmImport}
                disabled={!importPreview?.newItems.length && !importPreview?.updatedItems.length}
                className="ml-2"
              >
                Confirm Import
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {/* Import Summary Modal */}
        <Dialog open={!!importSummary} onOpenChange={() => setImportSummary(null)}>
          <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle className="text-xl font-semibold">Import Summary</DialogTitle>
              <DialogDescription>Import completed successfully</DialogDescription>
            </DialogHeader>

            {importSummary && (
              <div className="space-y-6">
                {/* Stats Dashboard */}
                <div className="grid grid-cols-3 gap-4 p-4 bg-gray-50 rounded-lg">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Total Items</p>
                    <p className="text-2xl font-semibold">{importSummary.totalInDb}</p>
                    <p className="text-xs text-gray-400">items in database</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Changes Made</p>
                    <div className="flex gap-2 items-baseline">
                      <span className="text-2xl font-semibold text-green-600">+{importSummary.newItems.length}</span>
                      <span className="text-sm text-gray-400">added</span>
                      <span className="text-2xl font-semibold text-blue-600 ml-2">~{importSummary.updatedItems.length}</span>
                      <span className="text-sm text-gray-400">updated</span>
                    </div>
                    {importSummary.failedItems.length > 0 && (
                      <p className="text-xs text-red-500">{importSummary.failedItems.length} items failed</p>
                    )}
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Success Rate</p>
                    <p className="text-2xl font-semibold">
                      {Math.round(
                        ((importSummary.newItems.length + importSummary.updatedItems.length) /
                          (importSummary.newItems.length + importSummary.updatedItems.length + importSummary.failedItems.length)) *
                          100
                      )}%
                    </p>
                    <p className="text-xs text-gray-400">of items processed</p>
                  </div>
                </div>

                {/* Added Items Section */}
                {importSummary.newItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-green-600 flex items-center gap-2">
                      <PlusCircle className="h-4 w-4" />
                      Items Added ({importSummary.newItems.length})
                    </h3>
                    <div className="bg-white rounded-lg border overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {importSummary.newItems.map((item, i) => (
                            <tr key={i} className="hover:bg-gray-50">
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{item}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Updated Items Section */}
                {importSummary.updatedItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-blue-600 flex items-center gap-2">
                      <RefreshCw className="h-4 w-4" />
                      Items Updated ({importSummary.updatedItems.length})
                    </h3>
                    <div className="bg-white rounded-lg border overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {importSummary.updatedItems.map((item, i) => (
                            <tr key={i} className="hover:bg-gray-50">
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{item}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Failed Items Section */}
                {importSummary.failedItems.length > 0 && (
                  <div className="space-y-2">
                    <h3 className="font-medium text-red-600 flex items-center gap-2">
                      <AlertCircle className="h-4 w-4" />
                      Failed Items ({importSummary.failedItems.length})
                    </h3>
                    <div className="bg-white rounded-lg border border-red-100 overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-red-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-red-500 uppercase tracking-wider">Item</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {importSummary.failedItems.map((item, i) => (
                            <tr key={i} className="hover:bg-red-50">
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-red-500">{item}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* All Success State */}
                {importSummary.failedItems.length === 0 && (
                  <div className="flex items-center justify-center p-4">
                    <div className="flex items-center gap-2 text-green-600">
                      <CheckCircle className="h-5 w-5" />
                      <span className="text-sm font-medium">All items were processed successfully</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            <DialogFooter className="mt-6">
              <Button onClick={() => setImportSummary(null)}>Close</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <AnalyseCSVDialog 
          open={showAnalysisDialog}
          onOpenChange={setShowAnalysisDialog}
          analysisResult={analysisResult}
          fileName={currentFileName}
        />
      </div>
      <ImportLoadingModal isOpen={isImporting} />
    </>
  );
}