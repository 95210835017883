import { useState } from 'react'
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { supabase } from '@/lib/supabaseClient'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { ChevronLeft, ChevronRight, Calendar, X } from 'lucide-react'
import { useIsMobile } from "@/hooks/use-mobile"
import { MobileHeader } from "@/components/layout/MobileHeader"

interface BatchItem {
  batch_number: string
  sku: string
  quantity: number
  weight_kilos: number | null
  expiry_date: string
  purchase_cost: number
  warehouse: string
  date_received: string
  inventory: {
    item_name: string
  }
}

const getExpiryText = (expiryDate: Date) => {
  const today = new Date()
  const timeLeft = expiryDate.getTime() - today.getTime()
  const daysLeft = Math.ceil(timeLeft / (1000 * 60 * 60 * 24))

  if (daysLeft < 0) {
    return 'EXPIRED'
  } else if (daysLeft === 0) {
    return '0 days until expiry'
  } else if (daysLeft < 7) {
    return `${daysLeft} days until expiry`
  } else if (daysLeft < 31) {
    const weeks = Math.floor(daysLeft / 7)
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} until expiry`
  } else {
    const months = Math.floor(daysLeft / 30.44) // average month length
    return `${months} ${months === 1 ? 'month' : 'months'} until expiry`
  }
}

const formatDate = (dateString: string | null | undefined) => {
  if (!dateString) return ''
  try {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return ''
    return format(date, 'dd.MM.yyyy')
  } catch (e) {
    return ''
  }
}

export default function ExpiryCalendar() {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedBatch, setSelectedBatch] = useState<BatchItem | null>(null)
  const isMobile = useIsMobile()

  const { data: batches } = useQuery<BatchItem[]>({
    queryKey: ['expiring-batches', format(currentDate, 'yyyy-MM')],
    queryFn: async () => {
      const startDate = startOfMonth(currentDate)
      const endDate = endOfMonth(currentDate)

      const { data, error } = await supabase
        .from('batches')
        .select(`
          *,
          inventory:inventory(item_name)
        `)
        .gte('expiry_date', format(startDate, 'yyyy-MM-dd'))
        .lte('expiry_date', format(endDate, 'yyyy-MM-dd'))
        .order('expiry_date')

      if (error) throw error
      return data || []
    },
  })

  // Group batches by expiry date
  const batchesByDate = batches?.reduce((acc: Record<string, BatchItem[]>, batch) => {
    const date = batch.expiry_date.split('T')[0]
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(batch)
    return acc
  }, {}) || {}

  // Calculate total value for each date
  const dateValues = Object.entries(batchesByDate).reduce(
    (acc: Record<string, number>, [date, items]) => {
      acc[date] = items.reduce(
        (sum, item) => sum + (item.quantity || item.weight_kilos || 0) * item.purchase_cost,
        0
      )
      return acc
    },
    {}
  )

  const nextMonth = () => {
    setCurrentDate(date => new Date(date.getFullYear(), date.getMonth() + 1))
  }

  const prevMonth = () => {
    setCurrentDate(date => new Date(date.getFullYear(), date.getMonth() - 1))
  }

  // Mobile list view component
  const MobileView = () => {
    const days = eachDayOfInterval({
      start: startOfMonth(currentDate),
      end: endOfMonth(currentDate),
    })

    return (
      <div className="lg:hidden">
        <MobileHeader title="Expiry Calendar" />
        
        <div className="fixed top-14 left-0 right-0 bg-white border-b z-10">
          <div className="flex items-center justify-between px-4 py-2">
            <button onClick={prevMonth} className="p-2">
              <ChevronLeft className="w-5 h-5" />
            </button>
            <h2 className="text-lg font-medium">
              {format(currentDate, 'MMMM yyyy')}
            </h2>
            <button onClick={nextMonth} className="p-2">
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="mt-[120px] px-4 pb-6">
          {days.map(day => {
            const formattedDate = format(day, 'yyyy-MM-dd')
            const dayBatches = batchesByDate[formattedDate] || []
            const totalValue = dateValues[formattedDate]

            if (dayBatches.length === 0) return null;

            return (
              <div key={formattedDate} className="mb-4">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <Calendar className="w-4 h-4 text-gray-500" />
                    <span className="font-medium">
                      {format(day, 'EEEE, MMMM d')}
                    </span>
                  </div>
                  {totalValue > 0 && (
                    <div className="text-sm px-2 py-1 bg-red-500 text-white rounded-sm">
                      CHF {Math.round(totalValue).toLocaleString('en-CH')}
                    </div>
                  )}
                </div>

                <div className="space-y-2">
                  {dayBatches.map((batch) => (
                    <div
                      key={batch.batch_number}
                      onClick={() => setSelectedBatch(batch)}
                      className="bg-white rounded-lg shadow-sm border border-gray-100 p-3"
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="font-medium">{batch.inventory.item_name}</div>
                          <div className="text-sm text-gray-600">
                            {batch.weight_kilos 
                              ? `${batch.weight_kilos} kg`
                              : `${batch.quantity} units`}
                          </div>
                        </div>
                        <div className="text-sm text-gray-600">
                          CHF {batch.purchase_cost}
                        </div>
                      </div>
                      <div className="mt-2 text-sm">
                        <span className="text-blue-600">
                          {getExpiryText(new Date(batch.expiry_date))}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  };

  // Desktop view component
  const DesktopView = () => (
    <div className="container mx-auto py-8">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Expiring Batches Calendar</h1>
        <div className="flex items-center gap-4">
          <button onClick={prevMonth} className="p-2">
            <ChevronLeft className="w-4 h-4" />
          </button>
          <h2 className="text-lg">
            {format(currentDate, 'MMMM yyyy')}
          </h2>
          <button onClick={nextMonth} className="p-2">
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="grid grid-cols-7 mb-4">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} className="text-sm text-gray-600">
              {day}
            </div>
          ))}
        </div>
        
        <div className="grid grid-cols-7 gap-1">
          {eachDayOfInterval({
            start: startOfMonth(currentDate),
            end: endOfMonth(currentDate),
          }).map(day => {
            const formattedDate = format(day, 'yyyy-MM-dd')
            const dayBatches = batchesByDate[formattedDate] || []
            const totalValue = dateValues[formattedDate]

            return (
              <div
                key={formattedDate}
                className={`
                  relative p-2 min-h-[120px] bg-gray-50 border border-gray-100 rounded-sm
                  ${dayBatches.length > 0 ? 'ring-1 ring-yellow-200 bg-yellow-50' : ''}
                `}
              >
                <div className="flex justify-between items-start">
                  <div className="text-sm font-medium">{format(day, 'd')}</div>
                  {totalValue > 0 && (
                    <div className="text-xs px-1.5 py-0.5 bg-red-500 text-white rounded-sm min-w-[2rem] text-center">
                      {Math.round(totalValue)}
                    </div>
                  )}
                </div>
                
                <div className="mt-2">
                  {dayBatches.map((batch) => (
                    <div
                      key={batch.batch_number}
                      className="mt-1 cursor-pointer hover:bg-yellow-100 rounded p-1 transition-colors"
                      onClick={() => setSelectedBatch(batch)}
                    >
                      <div className="text-sm truncate">{batch.inventory.item_name}</div>
                      <div className="text-xs text-gray-600">
                        {batch.weight_kilos 
                          ? `Qty: ${batch.weight_kilos} kg / CHF ${batch.purchase_cost}`
                          : `Qty: ${batch.quantity} / CHF ${batch.purchase_cost}`}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? <MobileView /> : <DesktopView />}

      <Dialog open={!!selectedBatch} onOpenChange={() => setSelectedBatch(null)}>
        <DialogContent className="max-w-md">
          <div className="flex items-center justify-between mb-4">
            <DialogTitle className="text-xl">
              {selectedBatch?.inventory.item_name}
              <div className="text-sm font-normal text-gray-600">SKU: {selectedBatch?.sku}</div>
            </DialogTitle>
            <div className="flex items-center gap-2">
              <div className="text-sm px-2 py-1 bg-red-500 text-white rounded-sm whitespace-nowrap">
                CHF {selectedBatch ? Math.round((selectedBatch.quantity || selectedBatch.weight_kilos || 0) * selectedBatch.purchase_cost).toLocaleString('en-CH') : 0}
              </div>
            </div>
          </div>

          <button 
            onClick={() => setSelectedBatch(null)}
            className="absolute right-4 top-4 rounded-sm opacity-70 hover:opacity-100"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>

          <div className="grid grid-cols-2 gap-y-4">
            <div>
              <div className="text-sm text-gray-600">Batch Number</div>
              <div>{selectedBatch?.batch_number}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Purchase Cost</div>
              <div>CHF {selectedBatch?.purchase_cost}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Quantity/Weight</div>
              <div>
                {selectedBatch?.weight_kilos 
                  ? `${selectedBatch.weight_kilos} kg`
                  : `${selectedBatch?.quantity} units`}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Warehouse</div>
              <div>{selectedBatch?.warehouse}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Date Received</div>
              <div>{formatDate(selectedBatch?.date_received)}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Expiry Date</div>
              <div>{formatDate(selectedBatch?.expiry_date)}</div>
            </div>
          </div>

          <div className="mt-4">
            {selectedBatch && (
              <div className="text-sm bg-blue-100 text-blue-800 px-3 py-2 rounded-md text-center">
                {getExpiryText(new Date(selectedBatch.expiry_date))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
