import React, { useState } from 'react';
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import type { Batch } from "@/types/batch";
import { MobileHeader } from "@/components/layout/MobileHeader";
import { Eye } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface MobileBatchesViewProps {
  batches: Batch[];
  filterText: string;
  onFilterChange: (value: string) => void;
  onBatchUpdated: () => void;
}

export function MobileBatchesView({ 
  batches,
  filterText,
  onFilterChange,
  onBatchUpdated
}: MobileBatchesViewProps) {
  const [selectedBatch, setSelectedBatch] = useState<Batch | null>(null);

  return (
    <div className="lg:hidden">
      <MobileHeader />
      <div className="fixed top-14 left-0 right-0 bg-white border-b z-10">
        <div className="px-4 py-2">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              type="text"
              placeholder="Search by batch number..."
              value={filterText}
              onChange={(e) => onFilterChange(e.target.value)}
              className="pl-9"
            />
          </div>
        </div>
      </div>

      <div className="mt-[48px]">
        {batches.map((batch) => (
          <div key={batch.batch_number} className="px-4 py-2 border-b bg-white">
            <div className="flex justify-between items-start gap-2">
              <h3 className="text-sm font-medium text-gray-900 leading-tight max-w-[75%]">{batch.item_name}</h3>
              <button
                onClick={() => setSelectedBatch(batch)}
                className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50"
              >
                <Eye className="h-4 w-4" />
              </button>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-blue-600">Expiry: </span>
                  <span className="text-sm text-gray-900">
                    {new Date(batch.expiry_date).toLocaleDateString()}
                  </span>
                </div>
                <div>
                  <span className="text-sm text-blue-600">Available: </span>
                  <span className="text-sm text-gray-900">
                    {batch.weight_kilos !== null 
                      ? `${batch.available_quantity ?? batch.weight_kilos} kg`
                      : (batch.available_quantity ?? batch.quantity)}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-sm text-blue-600">Warehouse: </span>
                  <span className="text-sm text-gray-900">{batch.warehouse}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Dialog open={!!selectedBatch} onOpenChange={() => setSelectedBatch(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Batch Details</DialogTitle>
          </DialogHeader>
          {selectedBatch && (
            <div className="space-y-4">
              <div>
                <h3 className="font-medium">{selectedBatch.item_name}</h3>
                <p className="text-sm text-gray-500">#{selectedBatch.batch_number}</p>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-gray-500">Expiry Date</span>
                  <p>{new Date(selectedBatch.expiry_date).toLocaleDateString()}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Warehouse</span>
                  <p>{selectedBatch.warehouse}</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Available</span>
                  <p>
                    {selectedBatch.weight_kilos !== null 
                      ? `${selectedBatch.available_quantity ?? selectedBatch.weight_kilos} kg`
                      : (selectedBatch.available_quantity ?? selectedBatch.quantity)}
                  </p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Purchase Cost</span>
                  <p>CHF {selectedBatch.purchase_cost}</p>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
