import Papa from 'papaparse';

interface CSVRow {
  sku: string;
  item_name: string;
  description?: string;
  is_weight_based: string;
  vat_rate?: string;
  [key: string]: string | undefined;
}

interface MissingFieldItem {
  sku?: string;
  itemName?: string;
  description?: string;
  isWeightBased?: string;
}

export interface CSVAnalysisResult {
  missingFields: {
    sku: MissingFieldItem[];
    itemName: MissingFieldItem[];
    isWeightBased: MissingFieldItem[];
  };
  duplicateSKUs: {
    [sku: string]: {
      itemName: string;
      description?: string;
      vatRate?: string;
      isWeightBased: boolean;
    }[];
  };
}

export async function analyzeCSVFile(file: File): Promise<CSVAnalysisResult> {
  return new Promise((resolve, reject) => {
    const result: CSVAnalysisResult = {
      missingFields: {
        sku: [],
        itemName: [],
        isWeightBased: [],
      },
      duplicateSKUs: {},
    };

    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy', // Skip empty rows and rows with only whitespace
      complete: (results) => {
        const rows = results.data as CSVRow[];
        const skuMap = new Map<string, CSVRow[]>();

        rows.forEach((row) => {
          // Check if row is completely empty
          const hasAnyValue = Object.values(row).some(val => val?.trim());
          if (!hasAnyValue) return; // Skip completely empty rows
          
          // Check for missing required fields
          if (!row.sku?.trim()) {
            result.missingFields.sku.push({
              itemName: row.item_name?.trim(),
              description: row.description?.trim()
            });
          }
          
          if (!row.item_name?.trim()) {
            result.missingFields.itemName.push({
              sku: row.sku?.trim(),
              description: row.description?.trim()
            });
          }
          
          if (!row.is_weight_based?.trim()) {
            result.missingFields.isWeightBased.push({
              sku: row.sku?.trim(),
              itemName: row.item_name?.trim()
            });
          }

          // Track duplicate SKUs
          if (row.sku?.trim()) {
            const existingRows = skuMap.get(row.sku) || [];
            skuMap.set(row.sku, [...existingRows, row]);
          }
        });

        // Process duplicate SKUs
        skuMap.forEach((rows, sku) => {
          if (rows.length > 1) {
            result.duplicateSKUs[sku] = rows.map(row => ({
              itemName: row.item_name || '',
              description: row.description,
              vatRate: row.vat_rate,
              isWeightBased: row.is_weight_based?.toLowerCase() === 'yes',
            }));
          }
        });

        resolve(result);
      },
      error: (error) => {
        reject(error);
      }
    });
  });
}
