import { supabase } from '@/lib/supabase';
import Logger from '@/utils/logger';

interface Invoice {
    status: string;
}

interface InvoiceItem {
    quantity: number | null;
    weight_kilos: number | null;
    invoice: Invoice;
}

export interface BatchBalance {
    storedQuantity: number;
    calculatedQuantity: number;
    needsReconciliation: boolean;
}

export const calculateBatchBalance = async (batchNumber: string): Promise<BatchBalance> => {
    try {
        // Get stored quantity and SKU from batches table
        const { data: batch, error: batchError } = await supabase
            .from('batches')
            .select('sku, available_quantity, weight_kilos, quantity')
            .eq('batch_number', batchNumber)
            .single();

        if (batchError) throw batchError;
        if (!batch) throw new Error(`Batch ${batchNumber} not found`);

        // Get item type (weight-based or quantity-based)
        const { data: inventory, error: inventoryError } = await supabase
            .from('inventory')
            .select('is_weight_based')
            .eq('sku', batch.sku)
            .single();

        if (inventoryError) throw inventoryError;
        if (!inventory) throw new Error(`SKU ${batch.sku} not found`);

        const isWeightBased = inventory.is_weight_based;
        
        // Get all invoice items for this batch
        const { data: invoiceItems, error: itemsError } = await supabase
            .from('invoice_items')
            .select(`
                quantity,
                weight_kilos,
                invoice:invoices (
                    status
                )
            `)
            .eq('batch_number', batchNumber)
            .returns<InvoiceItem[]>();

        if (itemsError) throw itemsError;

        // Filter only finalized invoices
        const finalizedInvoiceItems = (invoiceItems || []).filter(
            (item): item is InvoiceItem => item.invoice && item.invoice.status === 'final'
        );

        // Calculate total used quantity from finalized invoice items
        const usedQuantity = finalizedInvoiceItems.reduce((total, item) => {
            return total + (isWeightBased ? (item.weight_kilos || 0) : (item.quantity || 0));
        }, 0);

        // Original total quantity from when batch was created
        const originalQuantity = isWeightBased ? (batch.weight_kilos || 0) : (batch.quantity || 0);
        
        // Current stored quantity - always use available_quantity as source of truth
        const storedQuantity = batch.available_quantity ?? 
            (isWeightBased ? batch.weight_kilos : batch.quantity) ?? 0;

        // Calculate expected balance based on original quantity and used quantity from finalized invoices
        const calculatedQuantity = Math.max(0, originalQuantity - usedQuantity);

        // Compare with a small epsilon to handle floating point imprecision
        const epsilon = 0.001;
        const needsReconciliation = Math.abs(storedQuantity - calculatedQuantity) > epsilon;

        Logger.info('Batch balance calculated', {
            batchNumber,
            originalQuantity,
            storedQuantity,
            usedQuantity,
            calculatedQuantity,
            needsReconciliation,
            finalizedInvoiceItemsCount: finalizedInvoiceItems.length
        });

        return {
            storedQuantity,
            calculatedQuantity,
            needsReconciliation
        };
    } catch (error) {
        Logger.error('Error calculating batch balance', { error, batchNumber });
        throw error;
    }
};
