import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  PackageSearch,
  Boxes,
  FileText,
  CalendarClock,
  Info
} from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { InfoModal } from "@/components/info/InfoModal";

export default function Navigation() {
  const location = useLocation();
  const { isAdmin } = useAuth();

  const navigation = [
    { name: 'Inventory', href: '/inventory', icon: PackageSearch },
    { name: 'Batches', href: '/batches', icon: Boxes },
    { name: 'Invoices', href: '/invoices', icon: FileText },
    { name: 'Calendar', href: '/expiry-calendar', icon: CalendarClock },
  ];

  return (
    <nav className="flex items-center space-x-4">
      <Link to="/home" className="flex items-center mr-8">
        <img src="/ims_logo2.png" alt="Chefland IMS Logo" className="h-6 w-auto object-contain" />
      </Link>
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={cn(
            'flex items-center gap-2 px-4 py-2 text-sm font-medium rounded-md transition-colors duration-200',
            location.pathname === item.href
              ? 'bg-[#99AE4C] text-white'
              : 'text-white hover:bg-[#C3D48A] hover:text-[#304661]'
          )}
        >
          <item.icon className="h-5 w-5 flex-shrink-0" />
          {item.name}
        </Link>
      ))}
      <InfoModal onClose={() => {}} />
    </nav>
  );
}