import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import type { Invoice } from "@/types/invoice";
import { ViewInvoiceDialog } from "./ViewInvoiceDialog";
import { EditInvoiceDialog } from "./EditInvoiceDialog";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";
import { Eye, Trash2 } from "lucide-react";
import { useAuth } from '@/contexts/AuthContext';

interface InvoicesTableProps {
  invoices: Invoice[];
  onDelete: () => Promise<void>;
}

export function InvoicesTable({ invoices, onDelete }: InvoicesTableProps) {
  const { toast } = useToast();
  const { canEdit } = useAuth();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);

  const handleDelete = async (invoice: Invoice) => {
    try {
      Logger.info('Starting invoice deletion', { invoice });

      // For final invoices, restore the quantities to batches first
      if (invoice.status === 'final' && invoice.items) {
        for (const item of invoice.items) {
          // Get current batch quantities
          const { data: batch, error: fetchError } = await supabase
            .from('batches')
            .select('available_quantity, weight_kilos, quantity')
            .eq('batch_number', item.batch_number)
            .eq('sku', item.sku)
            .single();

          if (fetchError) {
            Logger.error('Error fetching batch', { error: fetchError, batch: item.batch_number });
            throw fetchError;
          }

          Logger.info('Current batch state', { 
            batch: item.batch_number, 
            current: batch,
            toRestore: item 
          });

          // Calculate amount to restore based on measurement type
          const amountToRestore = item.measurement_type === 'weight'
            ? (item.weight_kilos || 0)
            : (item.quantity || 0);

          // Get current available amount
          const currentAvailable = batch.available_quantity ?? 
            (item.measurement_type === 'weight' ? batch.weight_kilos : batch.quantity) ?? 0;

          Logger.info('Updating batch quantities', {
            batch: item.batch_number,
            currentAvailable,
            amountToRestore,
            newTotal: currentAvailable + amountToRestore
          });

          // Update batch quantities
          const { error: updateError } = await supabase
            .from('batches')
            .update({
              available_quantity: currentAvailable + amountToRestore
            })
            .eq('batch_number', item.batch_number)
            .eq('sku', item.sku);

          if (updateError) {
            Logger.error('Error updating batch', { error: updateError, batch: item.batch_number });
            throw updateError;
          }
        }
      }

      // Delete invoice items first
      const { error: itemsError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('invoice_id', invoice.id);

      if (itemsError) {
        Logger.error('Error deleting invoice items', itemsError);
        throw itemsError;
      }

      // Delete the invoice
      const { error: invoiceError } = await supabase
        .from('invoices')
        .delete()
        .eq('id', invoice.id);

      if (invoiceError) {
        Logger.error('Error deleting invoice', invoiceError);
        throw invoiceError;
      }

      toast({
        title: "Success",
        description: "Invoice deleted successfully"
      });

      onDelete();
    } catch (error: any) {
      Logger.error('Error in handleDelete', error);
      toast({
        variant: "destructive",
        title: "Error deleting invoice",
        description: error.message
      });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow className="bg-gray-50">
              <TableHead className="text-gray-900">Invoice #</TableHead>
              <TableHead className="text-gray-900">Company</TableHead>
              <TableHead className="text-gray-900">Date</TableHead>
              <TableHead className="text-gray-900">Status</TableHead>
              <TableHead className="text-gray-900">Items</TableHead>
              <TableHead className="text-gray-900">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices?.map((invoice) => (
              <TableRow key={invoice.id} className="hover:bg-gray-50">
                <TableCell className="text-gray-900">{invoice.invoice_number}</TableCell>
                <TableCell className="text-gray-900">{invoice.company}</TableCell>
                <TableCell className="text-gray-900">
                  {new Date(invoice.date).toLocaleDateString()}
                </TableCell>
                <TableCell className="text-gray-900">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    invoice.status === 'draft' ? 'bg-gray-100 text-gray-800' : 'bg-green-100 text-green-800'
                  }`}>
                    {invoice.status}
                  </span>
                </TableCell>
                <TableCell className="text-gray-900">
                  {invoice.items?.length || 0} items
                </TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => setSelectedInvoice(invoice)}
                      className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50"
                      title="View Invoice"
                    >
                      <Eye className="h-4 w-4" />
                    </button>
                    {canEdit && (
                      <>
                        <EditInvoiceDialog 
                          invoice={invoice} 
                          onInvoiceUpdated={onDelete}
                        />
                        <button
                          onClick={() => {
                            if (window.confirm('Are you sure you want to delete this invoice?')) {
                              handleDelete(invoice);
                            }
                          }}
                          className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                          title="Delete Invoice"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <ViewInvoiceDialog
        open={!!selectedInvoice}
        onClose={() => setSelectedInvoice(null)}
        invoice={selectedInvoice}
      />
    </div>
  );
}