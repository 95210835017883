import * as z from "zod";

export const batchFormSchema = z.object({
  sku: z.string().min(1, "SKU is required"),
  quantity: z.number().optional(),
  weight_kilos: z.number().optional(),
  expiry_date: z.string().optional(),
  purchase_cost: z.number().min(0, "Purchase cost must be positive"),
  warehouse: z.enum(["(+3°C)", "(+25°C)", "(-25°C)", "Paradiso", "Consignment"]),
  date_received: z.string(),
}).refine(
  (data) => data.quantity !== undefined || data.weight_kilos !== undefined,
  {
    message: "Either quantity or weight must be specified",
    path: ["quantity"], // path of error
  }
);

export type BatchFormValues = z.infer<typeof batchFormSchema>;

export interface Batch {
  batch_number: string;
  sku: string;
  quantity: number;
  weight_kilos: number | null;
  available_quantity: number;
  expiry_date: string;
  date_received: string;
  warehouse: string;
  purchase_cost: number;
  item_name?: string;
}