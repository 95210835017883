import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { CSVAnalysisResult } from '@/lib/csvAnalysis';
import { generateAnalysisReport } from '@/lib/reportGenerator';
import { useToast } from "@/components/ui/use-toast";
import { Download } from 'lucide-react';
import { useUserEmail } from '@/hooks/useUserEmail';

interface AnalyseCSVDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  analysisResult: CSVAnalysisResult | null;
  fileName?: string;
}

export function AnalyseCSVDialog({ 
  open, 
  onOpenChange, 
  analysisResult,
  fileName = 'unknown_file.csv' 
}: AnalyseCSVDialogProps) {
  const { toast } = useToast();
  const userEmail = useUserEmail();
  
  if (!analysisResult) return null;

  const hasMissingFields = Object.values(analysisResult.missingFields).some(arr => arr.length > 0);
  const hasDuplicates = Object.keys(analysisResult.duplicateSKUs).length > 0;

  const handleExport = () => {
    try {
      const report = generateAnalysisReport(
        analysisResult,
        fileName,
        userEmail || 'Unknown User'
      );

      // Create blob and download
      const blob = new Blob([report], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName.replace('.csv', '')}_reviewed.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      toast({
        title: "Report Exported",
        description: "Analysis report has been saved successfully",
      });
    } catch (error) {
      toast({
        title: "Export Failed",
        description: "Failed to export the analysis report",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>CSV Analysis Results</DialogTitle>
        </DialogHeader>
        <div className="space-y-6">
          {/* Missing Fields Section */}
          {hasMissingFields && (
            <div className="space-y-4">
              <h3 className="font-medium text-red-600">Missing Required Fields</h3>
              {analysisResult.missingFields.sku.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium mb-2">Missing SKU for Items:</h4>
                  <div className="bg-gray-50 p-3 rounded">
                    <p className="text-sm">
                      {analysisResult.missingFields.sku.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.itemName}
                          {index < analysisResult.missingFields.sku.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              )}
              {analysisResult.missingFields.itemName.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium mb-2">Missing Item Names for SKUs:</h4>
                  <div className="bg-gray-50 p-3 rounded">
                    <p className="text-sm">
                      {analysisResult.missingFields.itemName.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.sku}
                          {index < analysisResult.missingFields.itemName.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              )}
              {analysisResult.missingFields.isWeightBased.length > 0 && (
                <div>
                  <h4 className="text-sm font-medium mb-2">Missing Weight Based flag for Items:</h4>
                  <div className="bg-gray-50 p-3 rounded">
                    <p className="text-sm">
                      {analysisResult.missingFields.isWeightBased.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.itemName || item.sku}
                          {index < analysisResult.missingFields.isWeightBased.length - 1 ? ', ' : ''}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Duplicate SKUs Section */}
          {hasDuplicates && (
            <div className="space-y-4">
              <h3 className="font-medium text-red-600">Duplicate SKUs Found</h3>
              {Object.entries(analysisResult.duplicateSKUs).map(([sku, items]) => (
                <div key={sku} className="border rounded-lg p-4">
                  <h4 className="font-medium mb-3">SKU: {sku}</h4>
                  <div className="grid gap-3">
                    {items.map((item, index) => (
                      <div key={index} className="bg-gray-50 p-3 rounded">
                        <div className="grid grid-cols-2 gap-2 text-sm">
                          <div>
                            <span className="text-gray-500">Item Name:</span>{' '}
                            <span className="font-medium">{item.itemName}</span>
                          </div>
                          {item.description && (
                            <div>
                              <span className="text-gray-500">Description:</span>{' '}
                              <span className="font-medium">{item.description}</span>
                            </div>
                          )}
                          {item.vatRate && (
                            <div>
                              <span className="text-gray-500">VAT Rate:</span>{' '}
                              <span className="font-medium">{item.vatRate}%</span>
                            </div>
                          )}
                          <div>
                            <span className="text-gray-500">Weight Based:</span>{' '}
                            <span className="font-medium">{item.isWeightBased ? 'Yes' : 'No'}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* No Issues Found */}
          {!hasMissingFields && !hasDuplicates && (
            <div className="text-center py-6">
              <p className="text-green-600 font-medium">No issues found in the CSV file!</p>
              <p className="text-sm text-gray-600 mt-2">Your CSV file looks good and is ready for import.</p>
            </div>
          )}
        </div>
        <DialogFooter className="flex justify-between">
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={handleExport}
              className="gap-2"
            >
              <Download className="h-4 w-4" />
              Export Report
            </Button>
          </div>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
