import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { BatchFormFields } from "./BatchFormFields";
import { batchFormSchema, type BatchFormValues } from "@/types/batch";
import { useAuth } from '@/contexts/AuthContext';

interface AddBatchDialogProps {
  onBatchAdded: () => void;
}

export function AddBatchDialog({ onBatchAdded }: AddBatchDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();
  const { canEdit } = useAuth();

  if (!canEdit) return null;

  const form = useForm<BatchFormValues>({
    resolver: zodResolver(batchFormSchema),
    defaultValues: {
      sku: "",
      quantity: undefined,
      weight_kilos: undefined,
      expiry_date: undefined,
      date_received: new Date().toISOString().split('T')[0],
      purchase_cost: undefined,
      warehouse: "(+25°C)",
    },
  });

  const onSubmit = async (values: BatchFormValues) => {
    try {
      // Get the latest batch number
      const { data: latestBatch } = await supabase
        .from('batches')
        .select('batch_number')
        .order('batch_number', { ascending: false })
        .limit(1);

      // Generate new batch number
      let newBatchNumber = 'B-001';
      if (latestBatch && latestBatch.length > 0) {
        const lastNumber = parseInt(latestBatch[0].batch_number.split('-')[1]);
        newBatchNumber = `B-${String(lastNumber + 1).padStart(3, '0')}`;
      }

      // Remove available_quantity from the insert as it's generated
      const { error } = await supabase
        .from('batches')
        .insert([{
          batch_number: newBatchNumber,
          ...values,
          available_quantity: values.weight_kilos !== undefined ? values.weight_kilos : values.quantity,
          date_received: new Date().toISOString().split('T')[0],
        }]);

      if (error) throw error;

      toast({
        title: "Batch added successfully",
        description: `New batch ${newBatchNumber} for SKU ${values.sku} has been added.`
      });

      form.reset();
      setOpen(false);
      onBatchAdded();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error adding batch",
        description: error.message
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={(newOpen) => {
      if (!newOpen) {
        form.reset({
          sku: "",
          quantity: undefined,
          weight_kilos: undefined,
          expiry_date: undefined,
          date_received: new Date().toISOString().split('T')[0],
          purchase_cost: undefined,
          warehouse: "(+25°C)",
        });
      }
      setOpen(newOpen);
    }}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="w-4 h-4 mr-2" />
          Add Batch
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-right">Add New Batch</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <BatchFormFields form={form} />
            <Button type="submit" className="w-full">Add Batch</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}