import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Invoice } from '@/types/invoice';

interface ViewInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: Invoice | null;
}

export const ViewInvoiceDialog: React.FC<ViewInvoiceDialogProps> = ({ open, onClose, invoice }) => {
  if (!invoice) return null;

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">Invoice #{invoice.invoice_number}</DialogTitle>
          <div className="mt-2">
            <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium
              ${invoice.status === 'draft' ? 'bg-gray-100 text-gray-800' : 'bg-green-100 text-green-800'}`}>
              {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
            </span>
          </div>
        </DialogHeader>

        <div className="mt-6 space-y-6">
          {/* Invoice Details Card */}
          <Card className="p-6 bg-gray-50">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <h3 className="text-sm font-medium text-gray-500">Company</h3>
                <p className="mt-1 text-lg font-semibold">{invoice.company}</p>
              </div>
              <div>
                <h3 className="text-sm font-medium text-gray-500">Date</h3>
                <p className="mt-1 text-lg font-semibold">
                  {invoice.date ? new Date(invoice.date).toLocaleDateString() : 'Invalid Date'}
                </p>
              </div>
              {invoice.notes && (
                <div className="col-span-2">
                  <h3 className="text-sm font-medium text-gray-500">Notes</h3>
                  <p className="mt-1 text-gray-700">{invoice.notes}</p>
                </div>
              )}
            </div>
          </Card>

          {/* Items Table */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Items</h3>
            <div className="border rounded-lg overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow className="bg-gray-50">
                    <TableHead className="font-semibold">SKU</TableHead>
                    <TableHead className="font-semibold">Item Name</TableHead>
                    <TableHead className="font-semibold text-center">Batch #</TableHead>
                    <TableHead className="font-semibold text-center">Warehouse</TableHead>
                    <TableHead className="font-semibold text-right">Amount</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {invoice.items?.map((item, index) => (
                    <TableRow key={index} className="hover:bg-gray-50">
                      <TableCell className="font-medium">{item.sku}</TableCell>
                      <TableCell>{item.item_name}</TableCell>
                      <TableCell className="text-center">{item.batch_number}</TableCell>
                      <TableCell className="text-center">{item.warehouse}</TableCell>
                      <TableCell className="text-right">
                        {item.measurement_type === 'weight' 
                          ? `${item.weight_kilos} kg`
                          : item.quantity}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>

          <div className="flex justify-end mt-6">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};