import React from 'react';
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import Logger from "@/utils/logger";

export function ExportInventoryButton() {
  const { toast } = useToast();

  const handleExport = async () => {
    try {
      Logger.info('Starting inventory export');

      // Fetch all inventory items
      const { data: inventory, error } = await supabase
        .from('inventory')
        .select('*')
        .order('item_name');

      if (error) throw error;

      if (!inventory || inventory.length === 0) {
        toast({
          title: "No data to export",
          description: "The inventory is empty",
          variant: "destructive"
        });
        return;
      }

      // Convert to CSV format
      const headers = ['sku,item_name,description,vat_rate,is_weight_based'];
      const rows = inventory.map(item => {
        return [
          item.sku,
          item.item_name,
          item.description || '',
          item.vat_rate,
          item.is_weight_based
        ].map(field => {
          // Escape fields that contain commas or quotes
          if (typeof field === 'string' && (field.includes(',') || field.includes('"'))) {
            return `"${field.replace(/"/g, '""')}"`;
          }
          return field;
        }).join(',');
      });

      const csvContent = [...headers, ...rows].join('\n');
      
      // Create and trigger download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `inventory_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      Logger.info('Export completed successfully', { rowCount: inventory.length });
      
      toast({
        title: "Export successful",
        description: `Exported ${inventory.length} items to CSV`
      });

    } catch (error: any) {
      Logger.error('Export failed', error);
      toast({
        title: "Export failed",
        description: error.message,
        variant: "destructive"
      });
    }
  };

  return (
    <Button
      variant="outline"
      size="sm"
      className="gap-2"
      onClick={handleExport}
    >
      <Download className="h-4 w-4" />
      Export CSV
    </Button>
  );
} 