import React from "react";
import { Outlet } from "react-router-dom";
import Header from "@/components/layout/Header";

const Index = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#304661]/5 to-[#C3D48A]/10">
      <Header />
      <main className="p-8">
        <div className="mx-auto max-w-7xl bg-white rounded-lg shadow-sm p-6">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Index;