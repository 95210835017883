import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Loader2, AlertCircle, Download } from "lucide-react";
import { useState, useEffect } from "react";
import { calculateBatchBalance } from "@/utils/calculations";
import { supabase } from "@/lib/supabase";
import Logger from "@/utils/logger";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";
import { AuditResultsDialog } from "@/components/audit/AuditResultsDialog";

interface BatchAuditButtonProps {
    batches: any[];
    onAuditComplete: (results: any) => void;
}

export function BatchAuditButton({ batches, onAuditComplete }: BatchAuditButtonProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [lastRunTime, setLastRunTime] = useState<Date | null>(null);
    const [discrepancyCount, setDiscrepancyCount] = useState(0);
    const [auditResults, setAuditResults] = useState<any[]>([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        loadPendingAudits();
    }, []);

    const loadPendingAudits = async () => {
        try {
            Logger.info('Loading pending audits');
            const { data, error } = await supabase
                .from('batch_audit_results')
                .select(`
                    *,
                    inventory(item_name, is_weight_based)
                `)
                .eq('status', 'pending')
                .order('created_at', { ascending: false });

            if (error) {
                Logger.error('Error loading pending audits', { error });
                return;
            }

            if (data && data.length > 0) {
                const formattedResults = data.map(result => ({
                    batchNumber: result.batch_number,
                    itemName: result.inventory?.item_name || 'Unknown Item',
                    sku: result.sku,
                    storedQuantity: result.actual_value,
                    calculatedQuantity: result.expected_value,
                    difference: Math.abs(result.expected_value - result.actual_value),
                    isWeightBased: result.inventory?.is_weight_based || false
                }));

                setAuditResults(formattedResults);
                setDiscrepancyCount(formattedResults.length);
                setLastRunTime(new Date(data[0].created_at));
                Logger.info('Loaded pending audits', { count: formattedResults.length });
            }
        } catch (error) {
            Logger.error('Error in loadPendingAudits', error);
        }
    };

    const runAudit = async () => {
        setIsLoading(true);
        const results = [];
        let discrepancies = 0;

        try {
            // Get all unique SKUs from batches
            const skus = [...new Set(batches.map(batch => batch.sku))];
            
            // Fetch item names for all SKUs at once
            const { data: items, error } = await supabase
                .from('inventory')
                .select('sku, item_name, is_weight_based')
                .in('sku', skus);

            if (error) throw error;

            // Create a map of SKU to item info
            const skuToInfo = new Map(items?.map(item => [item.sku, item]));

            // Process each batch
            for (const batch of batches) {
                try {
                    const balance = await calculateBatchBalance(batch.batch_number);
                    const itemInfo = skuToInfo.get(batch.sku);
                    
                    if (balance.needsReconciliation) {
                        discrepancies++;
                        
                        // Store in database
                        const { error: insertError } = await supabase
                            .from('batch_audit_results')
                            .insert({
                                timestamp: new Date().toISOString(),
                                sku: batch.sku,
                                batch_number: batch.batch_number,
                                discrepancy_type: 'quantity_mismatch',
                                expected_value: balance.calculatedQuantity,
                                actual_value: balance.storedQuantity,
                                status: 'pending'
                            });

                        if (insertError) {
                            Logger.error('Error inserting audit result', { error: insertError, batch: batch.batch_number });
                        }

                        results.push({
                            batchNumber: batch.batch_number,
                            itemName: itemInfo?.item_name || 'Unknown Item',
                            sku: batch.sku,
                            storedQuantity: balance.storedQuantity,
                            calculatedQuantity: balance.calculatedQuantity,
                            difference: Math.abs(balance.storedQuantity - balance.calculatedQuantity),
                            isWeightBased: itemInfo?.is_weight_based || false
                        });
                    }
                } catch (error) {
                    Logger.error('Error calculating batch balance', { error, batchNumber: batch.batch_number });
                }
            }

            setDiscrepancyCount(discrepancies);
            setAuditResults(results);
            setLastRunTime(new Date());
            onAuditComplete(results);
            
            if (results.length > 0) {
                setShowResults(true);
            }

        } catch (error) {
            Logger.error('Error running audit', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleReconcile = async (result: any) => {
        try {
            Logger.info('Reconciling audit result', { batchNumber: result.batchNumber });
            
            const { error } = await supabase
                .from('batch_audit_results')
                .update({
                    status: 'resolved',
                    resolved_at: new Date().toISOString(),
                    resolved_by: (await supabase.auth.getUser()).data.user?.email
                })
                .eq('batch_number', result.batchNumber)
                .eq('status', 'pending');

            if (error) {
                Logger.error('Error reconciling audit result', { error });
                return;
            }

            setAuditResults(prev => prev.filter(r => r.batchNumber !== result.batchNumber));
            if (auditResults.length <= 1) {
                setShowResults(false);
            }
            setDiscrepancyCount(prev => prev - 1);
            Logger.info('Successfully reconciled audit result', { batchNumber: result.batchNumber });
        } catch (error) {
            Logger.error('Error in handleReconcile', error);
        }
    };

    const formatReportLine = (values: string[], widths: number[]) => {
        return values.map((val, i) => val.toString().padEnd(widths[i])).join(' | ');
    };

    const exportAudit = async () => {
        if (auditResults.length === 0) return;

        const formatNumber = (num: number, isWeightBased: boolean) => {
            return isWeightBased ? num.toFixed(2) : Math.round(num).toString();
        };

        // Get current user's email
        const { data: { user } } = await supabase.auth.getUser();
        const userEmail = user?.email || 'Unknown User';

        // Define column widths
        const widths = [12, 30, 10, 15, 15, 15, 8];
        const headers = ['Batch #', 'Item Name', 'SKU', 'System Balance', 'Expected Balance', 'Difference', 'Unit'];

        // Create header section
        const headerSection = [
            '='.repeat(120),
            'INVENTORY MANAGEMENT SYSTEM - BATCH BALANCE AUDIT REPORT',
            '='.repeat(120),
            '',
            `Date Generated: ${new Date().toLocaleString()}`,
            `Generated By: ${userEmail}`,
            `Total Discrepancies Found: ${auditResults.length}`,
            '',
            '-'.repeat(120),
            '',
            formatReportLine(headers, widths),
            '-'.repeat(120),
        ].join('\n');

        // Format data rows
        const dataRows = auditResults.map(result => formatReportLine([
            result.batchNumber,
            result.itemName,
            result.sku,
            formatNumber(result.storedQuantity, result.isWeightBased),
            formatNumber(result.calculatedQuantity, result.isWeightBased),
            formatNumber(result.difference, result.isWeightBased),
            result.isWeightBased ? 'kg' : 'units'
        ], widths)).join('\n');

        // Add summary section
        const summarySection = [
            '',
            '-'.repeat(120),
            'SUMMARY',
            '-'.repeat(120),
            `Total Items Checked: ${batches.length}`,
            `Items with Discrepancies: ${auditResults.length}`,
            `Accuracy Rate: ${((batches.length - auditResults.length) / batches.length * 100).toFixed(1)}%`,
            '='.repeat(120)
        ].join('\n');

        const fullReport = `${headerSection}\n${dataRows}\n${summarySection}`;

        // Create and trigger download
        const blob = new Blob([fullReport], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `batch-audit-${new Date().toISOString().split('T')[0]}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    return (
        <div className="flex items-center gap-2">
            <Button
                variant="outline"
                className="bg-white"
                onClick={runAudit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                ) : (
                    <AlertCircle className="h-4 w-4 mr-2" />
                )}
                Run Balance Audit
                {discrepancyCount > 0 && (
                    <Badge variant="destructive" className="ml-2">
                        {discrepancyCount}
                    </Badge>
                )}
            </Button>

            {lastRunTime && (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="text-sm text-gray-500">
                            Last run: {lastRunTime.toLocaleTimeString()}
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Full date: {lastRunTime.toLocaleString()}</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            )}

            {auditResults.length > 0 && (
                <Button 
                    variant="outline" 
                    className="bg-white"
                    onClick={exportAudit}
                >
                    <Download className="h-4 w-4 mr-2" />
                    Export Results
                </Button>
            )}

            <AuditResultsDialog
                open={showResults}
                onOpenChange={setShowResults}
                results={auditResults}
                onReconcile={handleReconcile}
            />
        </div>
    );
}
