import React, { useState } from 'react';
import { Menu, LogOut } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';

interface MobileHeaderProps {
  title?: string;
}

export function MobileHeader({ title }: MobileHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const displayName = user?.email?.split('@')[0] || 'User';

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  // Get current page title
  const getPageTitle = () => {
    if (title) return title;
    switch (location.pathname) {
      case '/inventory':
        return 'Master Inventory';
      case '/batches':
        return 'Batches';
      case '/invoices':
        return 'Invoices';
      default:
        return 'IMS';
    }
  };

  return (
    <div className="lg:hidden">
      <header className="fixed top-0 left-0 right-0 z-50 bg-[#2C3E50] border-b border-[#34495E]">
        <div className="flex items-center justify-between h-14 px-4">
          <div className="flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 -ml-2 text-white"
            >
              <Menu className="h-6 w-6" />
            </button>
            <span className="ml-4 text-lg text-white font-semibold">{getPageTitle()}</span>
          </div>
          <div className="flex items-center">
            <span className="text-white text-sm mr-4">{displayName}</span>
            <button
              onClick={handleSignOut}
              className="p-2 -mr-2 text-white"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>

        {isOpen && (
          <nav className="absolute w-full bg-white border-b shadow-lg">
            <Link 
              to="/home" 
              className="block px-4 py-3 hover:bg-gray-50 text-[#2C3E50] font-medium"
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link 
              to="/inventory" 
              className="block px-4 py-3 hover:bg-gray-50 text-[#2C3E50] font-medium"
              onClick={() => setIsOpen(false)}
            >
              Inventory
            </Link>
            <Link 
              to="/batches" 
              className="block px-4 py-3 hover:bg-gray-50 text-[#2C3E50] font-medium"
              onClick={() => setIsOpen(false)}
            >
              Batches
            </Link>
            <Link 
              to="/invoices" 
              className="block px-4 py-3 hover:bg-gray-50 text-[#2C3E50] font-medium"
              onClick={() => setIsOpen(false)}
            >
              Invoices
            </Link>
            <Link 
              to="/expiry-calendar" 
              className="block px-4 py-3 hover:bg-gray-50 text-[#2C3E50] font-medium"
              onClick={() => setIsOpen(false)}
            >
              Calendar
            </Link>
          </nav>
        )}
      </header>
    </div>
  );
} 