import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/components/ui/use-toast";
import { formSchema, VATRate } from "@/types/inventory";
import type { InventoryItem } from "@/types/inventory";
import * as z from "zod";
import { Pencil } from "lucide-react";

interface EditInventoryDialogProps {
  item: InventoryItem;
  onItemUpdated: () => void;
  trigger?: React.ReactNode;
}

export function EditInventoryDialog({ item, onItemUpdated, trigger }: EditInventoryDialogProps) {
  const [open, setOpen] = React.useState(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      sku: item.sku,
      item_name: item.item_name,
      description: item.description || "",
      vat_rate: item.vat_rate as VATRate,
      is_weight_based: item.is_weight_based
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { error } = await supabase
        .from('inventory')
        .update({
          item_name: values.item_name,
          description: values.description,
          vat_rate: values.vat_rate,
          is_weight_based: values.is_weight_based
        })
        .eq('sku', item.sku);

      if (error) throw error;

      toast({
        title: "Success",
        description: `${values.item_name} has been updated.`
      });

      setOpen(false);
      onItemUpdated();
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error updating product",
        description: error.message
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50">
          <Pencil className="h-4 w-4" />
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-md bg-white">
        <DialogHeader className="bg-gray-50 p-4 rounded-t-lg">
          <DialogTitle className="text-gray-900">Edit Product - {item.sku}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 p-6">
            <FormField
              control={form.control}
              name="item_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-900">Name</FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="Enter product name" 
                      className="bg-white text-gray-900 border-gray-200"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage className="text-red-500" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-900">Description</FormLabel>
                  <FormControl>
                    <Input 
                      placeholder="Enter description" 
                      className="bg-white text-gray-900 border-gray-200"
                      {...field} 
                    />
                  </FormControl>
                  <FormMessage className="text-red-500" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="vat_rate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-900">VAT Rate</FormLabel>
                  <Select
                    value={field.value.toString()}
                    onValueChange={(value) => {
                      const rate = parseFloat(value) as VATRate;
                      if (rate === 8.1 || rate === 2.6) {
                        field.onChange(rate);
                      }
                    }}
                  >
                    <SelectTrigger className="bg-white text-gray-900 border-gray-200">
                      <SelectValue placeholder="Select VAT rate" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="8.1">8.1%</SelectItem>
                      <SelectItem value="2.6">2.6%</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage className="text-red-500" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="is_weight_based"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tracking Type</FormLabel>
                  <FormControl>
                    <div className="flex items-center gap-2">
                      <span className={`text-sm ${!field.value ? 'font-bold text-blue-600' : ''}`}>QTY</span>
                      <button
                        type="button"
                        role="switch"
                        aria-checked={field.value}
                        className={`
                          relative inline-flex h-6 w-11 items-center rounded-full
                          ${field.value ? 'bg-red-600' : 'bg-blue-600'}
                          transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        `}
                        onClick={() => field.onChange(!field.value)}
                      >
                        <span
                          className={`
                            ${field.value ? 'translate-x-6' : 'translate-x-1'}
                            inline-block h-4 w-4 transform rounded-full bg-white transition-transform
                          `}
                        />
                      </button>
                      <span className={`text-sm ${field.value ? 'font-bold text-red-600' : ''}`}>Weight</span>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" className="w-full">Update Product</Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
} 