interface LogData {
  [key: string]: any;
}

class Logger {
  private static formatData(data: LogData): string {
    return Object.entries(data)
      .map(([key, value]) => {
        const formattedValue = typeof value === 'object' 
          ? JSON.stringify(value, null, 2)
          : value;
        return `${key}: ${formattedValue}`;
      })
      .join('\n');
  }

  static debug(message: string, data?: LogData) {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] DEBUG: ${message}`);
    if (data) {
      console.log('Data:', this.formatData(data));
    }
  }

  static error(message: string, error?: any) {
    const timestamp = new Date().toISOString();
    console.error(`[${timestamp}] ERROR: ${message}`);
    if (error) {
      if (error instanceof Error) {
        console.error('Error details:', {
          message: error.message,
          stack: error.stack,
          ...error
        });
      } else {
        console.error('Error details:', error);
      }
    }
  }

  static info(message: string, data?: LogData) {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] INFO: ${message}`);
    if (data) {
      console.log('Data:', this.formatData(data));
    }
  }
}

export default Logger; 