import React from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Eye, Info } from "lucide-react";
import { useState } from "react";
import { Batch } from "@/types/batch";
import { supabase } from "@/lib/supabase";
import { useQuery } from "@tanstack/react-query";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface ViewBatchDialogProps {
  batch: Batch;
}

export function ViewBatchDialog({ batch }: ViewBatchDialogProps) {
  const [open, setOpen] = useState(false);

  // Fetch SKU details when dialog is opened
  const { data: skuDetails } = useQuery({
    queryKey: ['sku', batch.sku, open],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('inventory')
        .select('item_name, is_weight_based')
        .eq('sku', batch.sku)
        .single();
      
      if (error) throw error;
      return data;
    },
    enabled: open,
  });

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50"
      >
        <Eye className="h-4 w-4" />
      </button>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Batch Details - {batch.batch_number}</DialogTitle>
          </DialogHeader>
          
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm font-semibold text-gray-500">SKU</div>
                <div>{batch.sku}</div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">Product Name</div>
                <div>{skuDetails?.item_name}</div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">
                  {skuDetails?.is_weight_based ? 'Weight' : 'Quantity'}
                </div>
                <div>
                  {skuDetails?.is_weight_based ? (
                    <span>{batch.weight_kilos} kg</span>
                  ) : (
                    <span>{batch.quantity}</span>
                  )}
                </div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500 flex items-center gap-1">
                  Available
                  <TooltipProvider delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Info className="h-4 w-4 text-gray-400 hover:text-gray-600 cursor-help" />
                      </TooltipTrigger>
                      <TooltipContent 
                        className="bg-white border border-gray-200 shadow-lg rounded-md p-4"
                        sideOffset={5}
                      >
                        <div className="text-gray-900">
                          <p className="font-medium">This shows the current availability for this specific batch:</p>
                          <ul className="mt-2 space-y-1">
                            {skuDetails?.is_weight_based ? (
                              <>
                                <li>• <strong>Weight</strong>: Original batch weight ({batch.weight_kilos} kg)</li>
                                <li>• <strong>Available</strong>: Remaining weight in this batch ({batch.available_quantity ?? batch.weight_kilos} kg)</li>
                              </>
                            ) : (
                              <>
                                <li>• <strong>Quantity</strong>: Original batch size ({batch.quantity})</li>
                                <li>• <strong>Available</strong>: Remaining items in this batch ({batch.available_quantity ?? batch.quantity})</li>
                              </>
                            )}
                          </ul>
                          <p className="mt-2 text-sm text-gray-600">
                            The difference represents {skuDetails?.is_weight_based ? 'weight' : 'items'} from this batch that have been sold or allocated to pending orders.
                          </p>
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <div>
                  <span>
                    {skuDetails?.is_weight_based 
                      ? `${batch.available_quantity ?? batch.weight_kilos} kg`
                      : batch.available_quantity ?? batch.quantity
                    }
                  </span>
                </div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">Expiry Date</div>
                <div>{batch.expiry_date || 'N/A'}</div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">Date Received</div>
                <div>{batch.date_received}</div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">Purchase Cost</div>
                <div>CHF {batch.purchase_cost.toFixed(2)}</div>
              </div>
              <div>
                <div className="text-sm font-semibold text-gray-500">Warehouse</div>
                <div>{batch.warehouse}</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
