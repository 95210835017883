import { CSVAnalysisResult } from './csvAnalysis';

export function generateAnalysisReport(
  analysisResult: CSVAnalysisResult,
  fileName: string,
  userName: string
): string {
  const now = new Date();
  const dateStr = now.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  const timeStr = now.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  });

  let report = `# CSV Analysis Report
Generated by: ${userName}
Date: ${dateStr}
Time: ${timeStr}
File Analyzed: ${fileName}

`;

  // Missing Fields Section
  const hasMissingFields = Object.values(analysisResult.missingFields).some(arr => arr.length > 0);
  if (hasMissingFields) {
    report += `## Missing Required Fields\n\n`;
    
    if (analysisResult.missingFields.sku.length > 0) {
      report += `### Missing SKUs\nThe following items are missing SKU values:\n`;
      analysisResult.missingFields.sku.forEach(item => {
        if (item.itemName) {
          report += `- Item: ${item.itemName}\n`;
        }
      });
      report += '\n';
    }
    
    if (analysisResult.missingFields.itemName.length > 0) {
      report += `### Missing Item Names\nThe following items are missing names:\n`;
      analysisResult.missingFields.itemName.forEach(item => {
        if (item.sku) {
          report += `- SKU: ${item.sku}\n`;
        }
      });
      report += '\n';
    }
    
    if (analysisResult.missingFields.isWeightBased.length > 0) {
      report += `### Missing Weight Based Flags\nThe following items are missing Weight Based indicators:\n`;
      analysisResult.missingFields.isWeightBased.forEach(item => {
        const identifier = item.itemName || item.sku || 'Unknown Item';
        report += `- ${identifier}\n`;
      });
      report += '\n';
    }
  }

  // Duplicate SKUs Section
  const duplicateSKUs = Object.entries(analysisResult.duplicateSKUs);
  if (duplicateSKUs.length > 0) {
    report += `## Duplicate SKUs\n\n`;
    duplicateSKUs.forEach(([sku, items], index) => {
      report += `### SKU: ${sku}\n`;
      report += `${'='.repeat(40)}\n`; 
      items.forEach((item, itemIndex) => {
        report += `\nItem ${itemIndex + 1}:\n`;
        report += `- Item Name: ${item.itemName}\n`;
        if (item.description) report += `- Description: ${item.description}\n`;
        if (item.vatRate) report += `- VAT Rate: ${item.vatRate}%\n`;
        report += `- Weight Based: ${item.isWeightBased ? 'Yes' : 'No'}\n`;
      });
      if (index < duplicateSKUs.length - 1) {
        report += '\n' + '-'.repeat(40) + '\n\n';
      }
    });
    report += '\n';
  }

  // Summary Section
  report += `## Summary\n\n`;
  if (!hasMissingFields && duplicateSKUs.length === 0) {
    report += `✅ No issues found in the CSV file. The file is ready for import.\n`;
  } else {
    const issues = [];
    if (hasMissingFields) {
      const totalMissing = 
        analysisResult.missingFields.sku.length +
        analysisResult.missingFields.itemName.length +
        analysisResult.missingFields.isWeightBased.length;
      issues.push(`${totalMissing} missing field(s)`);
    }
    if (duplicateSKUs.length > 0) {
      issues.push(`${duplicateSKUs.length} duplicate SKU(s)`);
    }
    report += `⚠️ Found ${issues.join(' and ')}. Please review and correct these issues before importing.\n`;
  }

  return report;
}
