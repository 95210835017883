import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/lib/supabase";
import { AddInvoiceDialog } from "@/components/invoices/AddInvoiceDialog";
import { InvoicesTable } from "@/components/invoices/InvoicesTable";
import { MobileInvoicesView } from "@/components/invoices/MobileInvoicesView";
import type { Invoice } from "@/types/invoice";
import Logger from "@/utils/logger";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileHeader } from "@/components/layout/MobileHeader";

const Invoices = () => {
  const queryClient = useQueryClient();
  const [filterText, setFilterText] = useState("");
  const isMobile = useIsMobile();

  const { data: invoices, refetch } = useQuery({
    queryKey: ['invoices'],
    queryFn: async () => {
      Logger.info('Starting invoice fetch...');
      
      try {
        // First fetch all invoices
        const { data: invoiceData, error: invoiceError } = await supabase
          .from('invoices')
          .select('*')
          .order('date', { ascending: false });

        if (invoiceError) {
          Logger.error('Error fetching invoices', invoiceError);
          return [];
        }

        Logger.info('Fetched invoices', { count: invoiceData?.length });

        // Then fetch all invoice items with their inventory details
        const { data: itemsData, error: itemsError } = await supabase
          .from('invoice_items')
          .select(`
            *,
            inventory:inventory(item_name)
          `)
          .in('invoice_id', invoiceData.map(inv => inv.id));

        if (itemsError) {
          Logger.error('Error fetching invoice items', itemsError);
          return [];
        }

        Logger.info('Fetched invoice items', { count: itemsData?.length });

        // Group items by invoice
        const invoicesWithItems = invoiceData.map(invoice => {
          const items = itemsData
            .filter(item => item.invoice_id === invoice.id)
            .map(item => ({
              sku: item.sku,
              quantity: item.quantity,
              weight_kilos: item.weight_kilos,
              item_name: item.inventory?.item_name || '',
              batch_number: item.batch_number,
              warehouse: item.warehouse,
              measurement_type: item.measurement_type
            }));

          return {
            id: invoice.id,
            invoice_number: invoice.invoice_number,
            date: invoice.date,
            company: invoice.company,
            notes: invoice.notes,
            status: invoice.status,
            total_amount: invoice.total_amount,
            created_at: invoice.created_at,
            updated_at: invoice.updated_at,
            user_id: invoice.user_id,
            items
          };
        });

        Logger.info('Processed invoices', { 
          count: invoicesWithItems.length,
          sample: invoicesWithItems[0]
        });

        return invoicesWithItems;
      } catch (error) {
        Logger.error('Error in invoice fetch', error);
        return [];
      }
    }
  });

  const handleInvoiceDeleted = async () => {
    await queryClient.invalidateQueries({ queryKey: ['invoices'] });
    await refetch();
  };

  // Filter invoices based on search text
  const filteredInvoices = invoices?.filter(invoice => 
    invoice.invoice_number.toLowerCase().includes(filterText.toLowerCase()) ||
    invoice.company.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <div>
      {/* Desktop View */}
      {!isMobile && (
        <>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">Invoices</h1>
            <AddInvoiceDialog onInvoiceAdded={handleInvoiceDeleted} />
          </div>
          <div className="mb-8 flex items-center justify-between">
            <div className="flex-1 max-w-sm">
              <div className="relative">
                <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                <Input
                  placeholder="Search by invoice # or company..."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="pl-8"
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <InvoicesTable 
              invoices={filteredInvoices || []} 
              onDelete={handleInvoiceDeleted}
            />
          </div>
        </>
      )}

      {/* Mobile View */}
      {isMobile && (
        <div className="lg:hidden">
          <MobileHeader title="Invoices" />
          <div className="fixed top-14 left-0 right-0 bg-white border-b z-10">
            <div className="px-4 py-2">
              <div className="relative">
                <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                <Input
                  type="text"
                  placeholder="Search by invoice # or company..."
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  className="pl-9"
                />
              </div>
            </div>
          </div>

          <div className="mt-[120px] px-4">
            <MobileInvoicesView 
              invoices={filteredInvoices || []} 
              onDelete={handleInvoiceDeleted}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Invoices;